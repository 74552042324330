// import Icons from "../../../../components/icons";

import { GetLinks } from "../../../../core";

export default [
  {
    key: "/extra-tools",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-tablet-book  fs-1"
          // style={{ color: "#40E0D0" }}
        ></i>
      </span>
    ),
    label: "Extra Tools",
    children: [
      {
        key: "/recommendation-alerts",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/recommendation-alerts", "Recommendation & Alerts"),
      },
      {
        key: "/extra-tools/feedback-automation",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks(
          "/extra-tools/feedback-automation",
          "Feedback automation"
        ),
      },
      {
        key: "/extra-tools/account-health",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/extra-tools/account-health", "Account Health"),
      },
      {
        key: "/extra-tools/profit-analysis",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/extra-tools/profit-analysis", "Profit Analysis"),
      },
    ],
  },
];
