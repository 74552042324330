import { Tabs } from "antd";
import React from "react";
import EventTypes from "./lib/event-types";
import UserLogs from "./lib/user-logs";
import { Wrapper } from "./style";

const { TabPane } = Tabs;

const SellerActivity = () => {
  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <>
        <div className="card">
          <Tabs type="line">
            <TabPane tab={<span className="px-4">User Logs</span>} key="1">
              <UserLogs />
            </TabPane>
            <TabPane tab={<span className="px-4">Event Types</span>} key="2">
              <EventTypes />
            </TabPane>
          </Tabs>
        </div>
      </>
    </Wrapper>
  );
};

export default SellerActivity;
