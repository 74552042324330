import ListingOptimizer from "../modules/pages/listing-optimizer/index.js";
 
export default [
  {
    path: "/listing-optimizer",
    component: <ListingOptimizer />,
    exact: true,
  },

  
 
];
