import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  message,
  Select,
} from "antd";
import moment from "moment";
import { MakeApiCall } from "../../../../apis/make-api-call";

const CouponFormModal = ({ visible, onClose, onSubmit, initialValues }) => {
  const [form] = Form.useForm();

  // Set initial form values if editing
  if (initialValues) {
    form.setFieldsValue(initialValues);
  }

  // Handle form submission
  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values); // Pass form values to onSubmit
    } catch (error) {}
  };

  const [partnerList, setPartnerList] = useState([]);
  const [partnerLoading, setPartnerLoading] = useState(true);

  const getPartners = async () => {
    setPartnerLoading(true);
    const response = await MakeApiCall(`partner/list`, "get", null, true);

    if (response?.status) {
      setPartnerLoading(false);
      setPartnerList(
        Object.entries(response?.data)?.map(([key, value]) => ({
          label: value,
          value: key,
        }))
      );
    } else {
      setPartnerList([]);
      setPartnerLoading(false);
    }
  };

  useEffect(() => {
    getPartners();
    return () => {};
  }, []);

  return (
    <Modal
      visible={visible}
      title={initialValues ? "Edit Coupon" : "Add Coupon"}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={[
        <Button key="cancel" onClick={() => onClose()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleFormSubmit}>
          {initialValues ? "Update" : "Add"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...initialValues,
          partner_id: initialValues?.partner_id?.toString() || null,
        }}
      >
        <Form.Item
          label="Partner Name"
          name="partner_name"
          rules={[{ required: true, message: "Partner Name is required." }]}
        >
          <Select loading={partnerLoading} placeholder="Select a partner">
            {partnerList?.map((d, i) => (
              <Select.Option value={d?.value} key={i}>
                {d?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Amount (%)"
          name="coupon_amount"
          rules={[{ required: true, message: "Amount is required." }]}
        >
          <Input type="number" placeholder="Enter amount in %" />
        </Form.Item>

        <Form.Item
          label="Applies To"
          name="applies_to"
          rules={[{ required: true, message: "Please select an option." }]}
        >
          <Radio.Group>
            <Radio value="first_month">First month</Radio>
            <Radio value="life">Life</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Coupon Code"
          name="coupon_code"
          rules={[{ required: true, message: "Coupon Code is required." }]}
        >
          <Input placeholder="Enter coupon code" />
        </Form.Item>

        <Form.Item
          label="Set Expire"
          name="expiry_date"
          rules={[{ required: true, message: "Please set an expiry date." }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            disabledDate={(current) =>
              current && current < moment().endOf("day")
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CouponFormModal;
