import { LoadingOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MakeApiCall } from "../../../apis/make-api-call";
import { GlobalContext } from "../../../commonContext";
import { DisplayNotification } from "../../../config";
import moment from "moment";
import { HeaderWrapper } from "./style";

const Header = (props) => {
  const { sidebarToggle, setSidebarToggle } = props;
  const contextValue = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [full_path, set_full_path] = useState([]);
  const [updatingSelectedUser, setUpdatingSelectedUser] = useState(false);
  const [PSTtime, setPSTtime] = useState("");
  const [lastSyncing, setLastSyncing] = useState(true);
  const [lastSync, setLastSync] = useState("");

  useEffect(() => {
    const path_name = location.pathname.split("/");
    set_full_path(path_name);

    const now = new Date();
    setLastSyncing(true);
    GetLastSyncTime(path_name?.[1]);
  }, [location]);

  useEffect(() => {
    GetSubUserList();
    updatePSTTime(); // Update time immediately
    const intervalId = setInterval(updatePSTTime, 1000); // Update every second
    return () => clearInterval(intervalId);
  }, []);

  const GetLastSyncTime = async (page_name) => {
    console.log(page_name, "page_name");
    const response = await MakeApiCall(
      `user/last-update-report?page_name=${page_name}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLastSync(response?.data?.updated_at);
      setLastSyncing(false);
    } else {
      setLastSyncing(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const updatePSTTime = () => {
    const now = new Date();
    const options = {
      timeZone: "America/Los_Angeles",
      hour12: false, // Use 24-hour format
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    // const formattedTime = now.toLocaleString("en-US", options);

    const formattedTime = now.toLocaleString("en-US", options);

    // Extract date and time components
    const [month, day, year] = formattedTime.split(", ")[0].split("/");
    const time = formattedTime.split(", ")[1];

    // Combine to the desired format: DD/MM/YYYY HH:mm:ss
    const formattedDateTime = `${day}/${month}/${year} ${time}`;

    setPSTtime(formattedDateTime);
  };
  const getUserList = () =>
    contextValue?.sub_user_list?.user_list ||
    localStorage.getItem("sub_user_list");

  const GetSubUserList = async () => {
    //  const data = {
    //    user_type: 2,
    //    parent: 2,
    //  };
    localStorage.setItem("token_type", "main_token");
    const response = await MakeApiCall(
      `user/get-all-sub-user`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      if (response?.data?.length > 0) {
        localStorage.setItem("token_type", "");
        localStorage.setItem("sub_user_list", JSON.stringify(response?.data));
        contextValue?.updateCommonUserListVal({
          user_list: JSON.stringify(response?.data),
        });
      } else {
        localStorage.removeItem("sub_user_list");
      }
    } else {
      localStorage.setItem("token_type", "");
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const backToAdmin = () => {
    const admin_data = localStorage.getItem("admin_data");
    const data = JSON.parse(admin_data);
    const response = JSON.parse(data?.user_data);
    const newRes = {
      auth_token: data?.token,
      user_data: JSON.parse(data?.user_data),
    };

    contextValue?.updateCommonGlobalVal({
      isAdmin: 0,
      user_data: JSON.stringify(newRes?.user_data),
      user_type: newRes?.user_data?.user_type,
      sub_user_list: null,
      selected_sub_user: null,
    });
    localStorage.setItem("isAdmin", 0);
    localStorage.setItem("user_data", JSON.stringify(newRes?.user_data));
    localStorage.setItem("token", newRes?.auth_token);
    localStorage.setItem("user_type", newRes?.user_data?.user_type);
    localStorage.setItem("sub_user_list", null);
    localStorage.setItem("selected_sub_user", null);
    localStorage.removeItem("sub_user_list");
    navigate("/manage-user");
  };

  const UpdateSelectedSubUser = async (id) => {
    localStorage.setItem("token_type", "main_token");
    const response = await MakeApiCall(
      `user/switch-sub-user/${id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      localStorage.setItem(
        "sub_user_token",
        response?.data?.user_data?.sub_user?.auth_token
      );
      localStorage.setItem("selected_sub_user", id || null);
      localStorage.setItem("token_type", "");
      contextValue?.updateCommonGlobalVal({
        selected_sub_user: id,
      });
      setUpdatingSelectedUser(false);
    } else {
      localStorage.setItem("token_type", "");
      DisplayNotification("error", "Error", response?.message);
      setUpdatingSelectedUser(false);
    }
  };

  return (
    <HeaderWrapper>
      {" "}
      <div
        id="kt_header"
        className="slideInDown header mt-0 mt-lg-0 pt-lg-0"
        data-kt-sticky="true"
        data-kt-sticky-name="header"
        data-kt-sticky-offset="{lg: '300px'}"
        style={{
          zIndex: 97,
          position: "fixed",
          left: window.innerWidth >= 992 ? "320px" : "20px",
          borderRadius: "18px",
          right: "19px",
          top: "9px",
          background: `linear-gradient(
      90deg,
      rgb(54 153 255) 0%,
      rgb(59 126 197) 100% 
    )`,
          // background: "#cae2fa",
          backdropFilter: "blur(3px)",
          height: "74px",
          border: "1px solid #b7d0eb",
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
        }}
      >
        <div
          className="container-fluid d-flex align-items-stretch justify-content-between"
          style={{ margin: "10px 0px" }}
          id="kt_header_container"
        >
          <div
            className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-10 pb-lg-0"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
          >
            <h1
              className="d-flex flex-column title-text text-light fw-bold my-0 fs-2"
              style={{ textTransform: "capitalize" }}
            >
              {full_path?.[1] === "callback-sp"
                ? "Amazon SP API Credentials"
                : full_path?.[1] === "callback-ads"
                ? "Amazon Advertising Credentials"
                : full_path?.[1]?.replaceAll("-", " ")}
            </h1>

            <ul className="breadcrumb breadcrumb-dot fw-semibold fs-base my-1">
              <li className="breadcrumb-item text-muted">
                <span
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                  style={{ color: "#e3e3e3" }}
                >
                  Home
                </span>
              </li>
              {full_path?.map((d, i) => {
                if (d) {
                  const newPath = full_path.slice(0, i + 1)?.join("/");
                  return (
                    <>
                      <li
                        className="breadcrumb-item"
                        style={{ paddingLeft: "0.5rem", color: "#e3e3e3" }}
                      >
                        {full_path?.length - 1 === i ? (
                          <span
                            className={`breadcrumb-item`}
                            style={{
                              textTransform: "capitalize",
                              color: "#fff",
                            }}
                          >
                            {d === "callback-sp"
                              ? "Amazon SP API Credentials"
                              : d === "callback-ads"
                              ? "Amazon Advertising Credentials"
                              : d?.replaceAll("-", " ")}
                          </span>
                        ) : (
                          <span
                            // to={{ pathname: newPath }}
                            className={`breadcrumb-item `}
                            style={{
                              textTransform: "capitalize",
                              color: "#e3e3e3",
                            }}
                          >
                            {d === "callback-sp"
                              ? "Amazon SP API Credentials"
                              : d === "callback-ads"
                              ? "Amazon Advertising Credentials"
                              : d?.replaceAll("-", " ")}
                          </span>
                        )}
                      </li>
                    </>
                  );
                }
              })}
            </ul>
          </div>

          <div
            className="d-flex d-lg-none align-items-center ms-n3 me-2"
            onClick={() => {
              setSidebarToggle(!sidebarToggle);
            }}
          >
            <div
              className="btn btn-icon btn-active-icon-primary"
              id="kt_aside_toggle"
            >
              <i className="ki-duotone ki-abstract-14 fs-1 mt-1">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>

            <span className="d-flex align-items-center ">
              <img
                alt="Logo"
                src="/assets/media/aalacart_favicon.png"
                className="theme-light-show h-45px"
              />
            </span>
          </div>

          <div className="d-flex align-items-center flex-wrap mb-0 mb-lg-0 ">
            <div className="d-flex">
              {/* <div>
                <label className="fs-5 me-2" style={{ color: "#fff" }}>
                  Current PST Time :{" "}
                </label>
                <button
                  className="btn btn-sm btn-light-primary"
                  style={{ padding: "1px 9px" }}
                >
                  <label className="fw-bold fs-5">{PSTtime}</label>
                </button>
              </div> */}
              <div className="ms-5">
                <label className="fs-5 me-2" style={{ color: "#fff" }}>
                  Last Sync :{" "}
                </label>
                <button
                  className="btn btn-sm btn-light-primary"
                  style={{ padding: "1px 9px" }}
                >
                  <label className="fw-bold fs-5">
                    {lastSyncing ? (
                      <LoadingOutlined
                        style={{
                          color: "#008000",
                          fontSize: "20px",
                        }}
                      />
                    ) : lastSync ? (
                      moment(new Date(lastSync * 1000)).format(
                        "MMM DD, YYYY hh:mm:ss"
                      )
                    ) : (
                      "-"
                    )}
                  </label>
                </button>
              </div>
            </div>
            {contextValue?.data?.user_type == 2 && (
              <div className="d-flex align-items-center ms-3 ms-lg-4">
                <Select
                  options={JSON.parse(getUserList())?.map((d) => {
                    return { label: d?.name, value: d?.id };
                  })}
                  value={contextValue?.data?.selected_sub_user || null}
                  onChange={(e) => {
                    localStorage.setItem("user_type", 2);
                    setUpdatingSelectedUser(true);
                    UpdateSelectedSubUser(e);
                  }}
                  placeholder="Select"
                  loading={updatingSelectedUser}
                  disabled={updatingSelectedUser}
                  style={{ width: "200px" }}
                  size="large"
                />
              </div>
            )}

            {contextValue?.data?.isAdmin == 1 && (
              <div className="d-flex align-items-center ms-3 ms-lg-4">
                <Tooltip title="Back to admin" placement="left">
                  <span
                    onClick={() => {
                      backToAdmin();
                    }}
                    className="btn btn-icon btn-light-primary btn-outline w-40px h-40px position-relative border-0"
                  >
                    <i className="ki-outline ki-arrow-left fs-1 fadeInLeftInfinite" />
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
