// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/inventory-management",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-add-item fs-1"
          // style={{ color: "#FF7F50" }}
        ></i>
      </span>
    ),
    label: GetLinks("/inventory-management", "Inventory Management"),
  },
];
