// email-definations

// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/email-definations",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-sms fs-1"
          // style={{ color: "#afa019" }}
        ></i>
      </span>
    ),
    label: GetLinks("/email-definations", "Email Definations"),
    admin: true,
  },
  {
    key: "/coupon-codes",
    icon: (
      <span class="menu-icon">
        <i
          className="ki-outline ki-devices-2 fs-1"
          // style={{ color: "#DC143C" }}
        ></i>
      </span>
    ),
    label: GetLinks("/coupon-codes", "Coupon Codes"),
    admin: true,
  },
  {
    key: "/ad-defination",
    icon: (
      <span class="menu-icon">
        <i
          className="ki-outline ki-notepad fs-1"
          // style={{ color: "#40E0D0" }}
        ></i>
      </span>
    ),
    label: GetLinks("/ad-defination", "Ad Defination"),
    admin: true,
  },
  {
    key: "/seller-activity",
    icon: (
      <span class="menu-icon">
        <i
          className="ki-outline ki-people fs-1"
          // style={{ color: "#32CD32" }}
        ></i>
      </span>
    ),
    label: GetLinks("/seller-activity", "Seller Activity"),
    admin: true,
  },
];
