import styled from "styled-components";

export const CustomCardTitle = styled.div`
  width: fit-content;
  padding: 10px 30px 0px;
  margin-left: 10px;
  .d-card {
    background: #fff;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 18px 18px 0px 0px;
    position: relative;
    border-radius: 18px 18px 0px 0px;
    &::before {
      content: "";
      background: radial-gradient(circle at 0 0, #0000 70%, #fff 70% 100%);
      width: 1.5em;
      height: 1.5em;
      position: absolute;
      bottom: 0px;
      left: -18px;
    }
    &::after {
      content: "";
      width: 1.5em;
      height: 1.5em;
      position: absolute;
      background: radial-gradient(circle at 100% 0, #0000 70%, #fff 70% 100%);
      bottom: 0;
      right: -18px;
    }
  }
 
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
 
  @-webkit-keyframes slideInUp {
  0% {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  visibility: visible;
  }
  100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  }
  }
  @keyframes slideInUp {
  0% {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  visibility: visible;
  }
  100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  }
  } 
  
×
`;
