import React, {useState} from "react";

import { Card, Col, Row, ConfigProvider, Segmented, Button, Input, Select, Space,Typography, Flex, } from "antd";

import { Icon } from "@iconify/react/dist/iconify.js";
import { primaryColor } from "../../../config";
import { Wrapper } from "./style";
import ModalData from "./lib/modalData";
import ProductTable from "./lib/ProductTable";
import AddProduct from "./lib/AddProduct";


const { Title } = Typography;
function ListingOptimizer() {

  const [value, setValue] = useState('Show All');
  const [selectValue, setSelectValue] = useState(null); 
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modal1Open, setModal1Open] = useState(false);



  const customTheme = {
    components: {
      Segmented: {
        itemActiveBg: '#3699ff26',   // Active item background (light red)
        itemColor: '#000',         // Default item text color
        itemHoverBg: '#3699ff26',      // Hover background (lighter red)
        itemHoverColor: '#000',    // Hover text color
        itemSelectedBg: primaryColor, // Selected item background color (red)
        itemSelectedColor: 'white', // Selected item text color (white)
        trackBg: '#fff',  // Background for the Segmented container
        trackPadding: 4,  // Padding for the Segmented container
      },
    },
  };

  const handleChange = (selectedValue) => {
    // setSelectValue(selectedValue); // Update the value on selection
    setSelectValue(selectedValue);
  };




  
 

  return (
    <Wrapper className="container-fluid " id="kt_content_container">
      <Row gutter={16}>
        <Col span={24}>
          <Card title={
            <>
              <Space>
                <Input size="large" variant='filled' placeholder="Search by ASIN, SKU..." prefix={<Icon icon='tabler:search'></Icon>} />
                <Select
                    value={selectValue}  // Controlled component with value state
                    onChange={handleChange}
                    className="min-w-150px"
                    placeholder="Select Brand"
                    size="large"
                    variant='filled'
                    allowClear
                    options={[
                        { value: 'testbrand', label: 'Test Brand 1' },
                    ]}
                    />
              </Space>
            </>
          }  
          extra={
                <>
                   <ConfigProvider theme={customTheme}>
                    <Segmented
                      options={[
                        {
                          label: (
                            <span><span style={{ display: 'inline-block', width: 11, height: 11, borderRadius: '50%', backgroundColor: '#edba00', marginRight: 6, border: '3px solid #ffffff'}}></span>Show All</span>
                          ),
                          value: "Show All",
                        },
                        {
                          label: (
                            <span><span style={{ display: 'inline-block', width: 11, height: 11, borderRadius: '50%', backgroundColor: '#1dbd00', marginRight: 6, border: '3px solid #ffffff'}}></span>Listed</span>
                          ),
                          value: "Listed",
                        },
                        {
                          label: (
                            <span><span style={{ display: 'inline-block', width: 11, height: 11, borderRadius: '50%', backgroundColor: '#d90000', marginRight: 6, border: '3px solid #ffffff'}}></span>Unlisted</span>
                          ),
                          value: "Unlisted",
                        },
                      ]}
                      value={value}
                      className='me-3'
                      onChange={setValue}
                    />
                  </ConfigProvider>
                    <Button size='large' onClick={() => setModal1Open(true)} className='fs-7 fw-bold' type='primary'>Add Product</Button>
                </>
                }>
                <Row className="cus-card" gutter={[16, 16]}>
                  {/* Total Profit Card */}
                  <Col xl={8} md={12} sm={24}>
                    <Card className="prod-p-card border border-dashed border-primary" style={{ background: 'linear-gradient(180deg, rgb(247, 247, 247), rgb(33 150 243 / 17%))' }}>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <Flex align="center">
                            <Title level={5} className="m-0 me-4 text-gray-800" >LQS</Title>
                          </Flex>
                        </Col>
                        <Col>
                            <Title level={4} className="m-0">55.95%</Title>
                          {/* <MoneyCollectOutlined style={{ fontSize: '24px', color: 'white' }} /> */}
                        </Col>
                      </Row>
                      <Flex className="bg-white mt-3 rounded-2 listing" justify="center" style={{overflow:'hidden',border:'3px solid #fff', boxShadow:'0 0 15px #0000000f'}}>
                       <Row className=" w-100 " gutter={24}>
                        <Col className="px-7 py-2 r text-center" span={8}><span className="text-danger">Critic: <b>3</b></span></Col>
                        <Col className="px-7 py-2 w text-center" span={8}><span className="text-warning">Warning: <b>3</b></span></Col>
                        <Col className="px-7 py-2 g text-center" span={8}><span className="text-success">Good: <b>3</b></span></Col>
                       </Row>
                      </Flex>
                    
                    </Card>
                  </Col>
                  {/* Total Profit Card */}
                  <Col xl={8} md={12} sm={24}>
                    <Card className="prod-p-card border border-dashed border-primary" style={{ background: 'linear-gradient(180deg, rgb(247, 247, 247), rgb(33 150 243 / 17%))' }}>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <Flex align="center">
                            <Title level={5} className="m-0 me-4 text-gray-800" >Retail Readiness</Title>
                          </Flex>
                        </Col>
                        <Col>
                            <Title level={4} className="m-0">51.9%</Title>
                          {/* <MoneyCollectOutlined style={{ fontSize: '24px', color: 'white' }} /> */}
                        </Col>
                      </Row>
                      <Flex className="bg-white mt-3 rounded-2 listing" justify="center" style={{overflow:'hidden',border:'3px solid #fff', boxShadow:'0 0 15px #0000000f'}}>
                       <Row className=" w-100 " gutter={24}>
                        <Col className="px-7 py-2 r text-center" span={8}><span className="text-danger">Critic: <b>3</b></span></Col>
                        <Col className="px-7 py-2 w text-center" span={8}><span className="text-warning">Warning: <b>3</b></span></Col>
                        <Col className="px-7 py-2 g text-center" span={8}><span className="text-success">Good: <b>3</b></span></Col>
                       </Row>
                      </Flex>
                    
                    </Card>
                  </Col>
                  {/* Total Profit Card */}
                  <Col xl={8} md={12} sm={24}>
                    <Card className="prod-p-card border border-dashed border-primary" style={{ background: 'linear-gradient(180deg, rgb(247, 247, 247), rgb(33 150 243 / 17%))' }}>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <Flex align="center">
                            <Title level={5} className="m-0 me-4 text-gray-800" >BQS</Title>
                          </Flex>
                        </Col>
                        <Col>
                            <Title level={4} className="m-0">30.95%</Title>
                          {/* <MoneyCollectOutlined style={{ fontSize: '24px', color: 'white' }} /> */}
                        </Col>
                      </Row>
                      <Flex className="bg-white mt-3 rounded-2 listing" justify="center" style={{overflow:'hidden',border:'3px solid #fff', boxShadow:'0 0 15px #0000000f'}}>
                       <Row className=" w-100 " gutter={24}>
                        <Col className="px-7 py-2 r text-center" span={8}><span className="text-danger">Critic: <b>3</b></span></Col>
                        <Col className="px-7 py-2 w text-center" span={8}><span className="text-warning">Warning: <b>3</b></span></Col>
                        <Col className="px-7 py-2 g text-center" span={8}><span className="text-success">Good: <b>3</b></span></Col>
                       </Row>
                      </Flex>
                    
                    </Card>
                  </Col>

                

                </Row>
          </Card>
        </Col>

        <Col className="mt-5" span={24}>
            {/* Product list Component */}
              <ProductTable selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} setModalOpen={setModalOpen}/>
            {/* End Product list Component */}
              
        </Col>
      </Row>

      {/* Modal data */}
        <ModalData modalOpen={modalOpen} setModalOpen={setModalOpen} />
      {/* End Modal data */}

       {/* Modal data */}
       <AddProduct modal1Open={modal1Open} setModal1Open={setModal1Open} />
      {/* End Modal data */}
    </Wrapper>
  );
}

export default ListingOptimizer;
