import { Table } from "antd";
import React from "react";
import { Table1Wrapper } from "./style";

export default function Table2(props) {
  const {
    dataSource,
    columns,
    sortFilters = "",
    setSortFilters = () => {},
    setSortOrderColumns = () => {},
    onSortChange,
  } = props;

  const PropsFilter = (d) => {
    if (d?.localSort) {
      return {
        sorter: (a, b) => {
          return typeof a?.[d?.dataIndex] === "string"
            ? (a?.[d?.dataIndex] || "")?.localeCompare(b?.[d?.dataIndex] || "")
            : parseFloat(a?.[d?.dataIndex] || 0) -
                parseFloat(b?.[d?.dataIndex] || 0);
        },
      };
    }
    return {
      defaultSortOrder: sortFilters
        ? sortFilters?.startsWith("-")
          ? "ascend"
          : "descend"
        : "",
      sorter: () => {},
    };
  };

  const handleChange = (pagination, __, sorter, e) => {
    const { order, column } = sorter;
    const { current, pageSize } = pagination;

    try {
      if (!column?.localSort) {
        const sort = order
          ? `${order === "ascend" ? "-" : ""}${column?.dataIndex}`
          : "";
        setSortFilters(sort);
        setSortOrderColumns(column?.dataIndex);
        onSortChange(
          sort,
          e?.action === "sort" ? 1 : current,
          pageSize,
          column?.dataIndex
        );
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const columns_ = columns?.map((d) => {
    if (Object.hasOwn(d, "dataIndex") && (d?.sortOn || d?.localSort)) {
      return {
        ...d,
        ...PropsFilter(d),
      };
    }
    return d;
  });

  return (
    <Table1Wrapper>
      <Table
        dataSource={dataSource}
        onChange={handleChange}
        {...props}
        columns={columns_}
      />
      {/* <ConfigColumns columns={columns} /> */}
    </Table1Wrapper>
  );
}
