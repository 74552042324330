import { GetLinks } from "../../../../core";

export default [
  {
    key: "/advertisement",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-book fs-1"
          // style={{ color: "#B03060" }}
        ></i>
      </span>
    ),
    label: "Advertisement",
    children: [
      {
        key: "/advertisement-dashboard",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/advertisement-dashboard", "Advertisement Dashboard"),
      },
      {
        key: "/product-analytics",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/product-analytics", "Product Analytics"),
      },
      {
        key: "/campaign-management",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/campaign-management", "Campaign Management"),
      },
      {
        key: "/rule-management",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: "Rule Management",
        children: [
          {
            key: "/rule-book",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks("/rule-book", "Rule Book"),
          },
          {
            key: "/day-parting",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks("/day-parting", "Day Parting"),
          },
        ],
      },

      {
        key: "/ketword-management",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: "Keyword Management",
        children: [
          {
            key: "/keyword-targeting",
            icon: (
              <span className="menu-bullet">
                <span className="bullet bullet-dot" />
              </span>
            ),
            label: GetLinks("/keyword-targeting", "Keyword Targeting"),
          },
        ],
      },
      // {
      //   key: "/advertisement-report",
      //   icon: (
      //     <span className="menu-bullet">
      //       <span className="bullet bullet-dot" />
      //     </span>
      //   ),
      //   label: GetLinks("/advertisement-report", "Advertisement Report"),
      // },
    ],
  },
];
