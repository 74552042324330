import React, { useEffect, useState } from "react";
import LostWhs from "./lib/lost-whs";
import DamagedWhs from "./lib/damaged-whs";
import Disposed from "./lib/disposed";
import LostInbound from "./lib/lost-inbound";
import LostOutbound from "./lib/lost-outbound";
import FBAFees from "./lib/fba-fees";
import DamagedReturns from "./lib/damages-return";
import WrongFnsku from "./lib/wrong-fnsku";
import OrderDiscrepancies from "./lib/order-discrepancies";
import { MakeApiCall } from "../../../../apis/make-api-call";
import { DisplayNotification } from "../../../../config";
import Manager from "../manager/manager";

const ReimbursementDetails = () => {
  const [selected_tab, set_selected_tab] = useState("lost_whs");
  const [case_status_list, set_case_status_list] = useState([]);
  const [all_manager_list, set_all_manager_list] = useState([]);

  const GetCaseStatusList = async () => {
    const response = await MakeApiCall(
      `rms/get-case-status-list`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      set_case_status_list(response?.data?.records);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetAllManager = async () => {
    const response = await MakeApiCall(`get-all-manager`, `get`, null, true);
    if (response?.status) {
      set_all_manager_list(response?.data?.records);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  useEffect(() => {
    GetCaseStatusList();
    GetAllManager();
  }, []);

  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="row mb-5 px-0 mx-0">
        <div className="col-12">
          <button
            className={`btn ${
              selected_tab === "lost_whs" ? "btn-primary" : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("lost_whs");
            }}
          >
            Lost WHS
          </button>
          <button
            className={`btn ${
              selected_tab === "damaged_whs" ? "btn-primary" : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("damaged_whs");
            }}
          >
            Damaged WHS
          </button>
          <button
            className={`btn ${
              selected_tab === "disposed" ? "btn-primary" : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("disposed");
            }}
          >
            Disposed
          </button>
          <button
            className={`btn ${
              selected_tab === "lost_inbound" ? "btn-primary" : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("lost_inbound");
            }}
          >
            Lost Inbound
          </button>
          <button
            className={`btn ${
              selected_tab === "lost_outbound" ? "btn-primary" : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("lost_outbound");
            }}
          >
            Lost Outbound
          </button>
          {/* <button
            className={`btn ${
              selected_tab === "fba_fees" ? "btn-primary" : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("fba_fees");
            }}
          >
            FBA Fees
          </button> */}
          <button
            className={`btn ${
              selected_tab === "damaged_return"
                ? "btn-primary"
                : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("damaged_return");
            }}
          >
            Damaged Return
          </button>
          <button
            className={`btn ${
              selected_tab === "wrond_fnsku" ? "btn-primary" : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("wrond_fnsku");
            }}
          >
            Wrong FNSKU
          </button>
          {/*  <button
            className={`btn ${
              selected_tab === "order_discrepancies"
                ? "btn-primary"
                : "btn-secondary"
            } me-3 my-2`}
            onClick={() => {
              set_selected_tab("order_discrepancies");
            }}
          >
            Order Discrepancies
          </button> */}
        </div>
      </div>
      {selected_tab === "lost_whs" && (
        <LostWhs
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "damaged_whs" && (
        <DamagedWhs
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "disposed" && (
        <Disposed
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "lost_inbound" && (
        <LostInbound
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "lost_outbound" && (
        <LostOutbound
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "fba_fees" && (
        <FBAFees
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "damaged_return" && (
        <DamagedReturns
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "wrond_fnsku" && (
        <WrongFnsku
          case_status_list={case_status_list}
          manager_list={all_manager_list}
        />
      )}
      {selected_tab === "order_discrepancies" && <OrderDiscrepancies />}
    </div>
  );
};

export default ReimbursementDetails;
