import { ConfigProvider } from "antd";
import AppRoutes from "./app-routes";
import GlobalCommonContextProvider from "./commonContext";
import { useEffect } from "react";
import { GlobalDebug } from "./core";
import { AppStyleWrapper } from "./app-style";
function App() {
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          // fontFamily: "Open+Sans, sans-serif",
          fontFamily: "Inter, Helvetica, sans-serif",
          colorPrimary: "#3699ff",
          colorPrimaryHover: "#1f78d3",
        },
        components: {
          Select: {
            colorBorder: "#3699ff",
            selectorBg: "#f2f8ff",
          },
          Input: {
            colorBorder: "#3699ff",
            colorBgContainer: "#f2f8ff",
          },
          DatePicker: {
            colorBorder: "#3699ff",
            colorBgContainer: "#f2f8ff",
          },
          Menu: {
            itemBorderRadius: 33,
            subMenuItemBorderRadius: 34,
          },
        },
      }}
    >
      <GlobalCommonContextProvider>
        <AppStyleWrapper />
        <AppRoutes />
      </GlobalCommonContextProvider>
    </ConfigProvider>
  );
}

export default App;
