import KeywordTargeting from "../modules/pages/keyword-management/keyword-targeting/keyword-targeting";
import Keywords from "../modules/pages/keyword-management/keywords/keywords";

export default [
  {
    path: "/keyword-targeting",
    component: <KeywordTargeting />,
    exact: true,
  },
  // {
  //   path: "/keyword-management/keyword",
  //   component: <Keywords />,
  //   exact: true,
  // },
];
