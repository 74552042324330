import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis/make-api-call";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
} from "../../../../config";
import { RecommendationStyle } from "./style";
import { message, Select, Skeleton, Tooltip } from "antd";
import ProductListModal from "./lib/product-list-modal";
import { GlobalContext } from "../../../../commonContext";

const RecommendationAndAlerts = () => {
  const contextValue = useContext(GlobalContext);
  const [alert_list, set_alert_list] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allProductList, setAllProductList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isProductListLoading, setIsProductListLoading] = useState(false);
  const [selectedType, setselectedType] = useState("");
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  const [product_list_filter, set_product_list_filter] = useState({
    // marketplace_id: "ATVPDKIKX0DER",
    page: 1,
    "per-page": DefaultPerPage,
    type: "",
    marketplace_id: null,
  });

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_product_list_filter({
        ...product_list_filter,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetAlertsList(marketplace?.marketplace_list[0]?.marketplace_id);
    } else {
      setLoading(false);
    }
  };

  const GetAlertsList = async (marketplace_id) => {
    setLoading(true);
    const response = await MakeApiCall(
      `recommendation-alerts?marketplace_id=${marketplace_id}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_alert_list(response?.data);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetProductList = async (product_list_filter) => {
    const response = await MakeApiCall(
      `recommendation-alerts/product-list${ConvertParams(product_list_filter)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      message.destroy();
      setAllProductList(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
      setIsProductListLoading(false);
      setIsOpenModal(true);
    } else {
      setIsProductListLoading(false);
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const data = [
    {
      type: "Out of Stock",
      key_name: "out_of_stock",
      icon: (
        <img src="/assets/media/out-of-stock.png" style={{ width: "30px" }} />
      ),
      color: "#8d2ce1",
    },
    {
      type: "Non-Advertised",
      key_name: "non_advertised",
      icon: <img src="/assets/media/megaphone.png" style={{ width: "30px" }} />,
      color: "#f2235f",
    },
    {
      type: "BuyBox Affected Products",
      key_name: "buyBox_affected_products",
      icon: <img src="/assets/media/box.png" style={{ width: "30px" }} />,
      color: "#11b391",
    },
    {
      type: "Different SKUs of Same ASINs Not Being Advertised",
      key_name: "diff_sku_of_same_asin",
      icon: <img src="/assets/media/no-ads.png" style={{ width: "30px" }} />,
      color: "#f7981e",
    },
    {
      type: "Ineligible Ads",
      key_name: "ineligible_ads",
      icon: (
        <img src="/assets/media/advertisement.png" style={{ width: "30px" }} />
      ),
      color: "#84c64a",
    },
    {
      type: "Campaigns Getting Rejected",
      key_name: "campaigns_rejected",
      icon: <img src="/assets/media/file.png" style={{ width: "30px" }} />,
      color: "#f7981e",
    },
    {
      type: "Payment/CC Issue in Advertised Account",
      key_name: "payment_billing_notifications",
      icon: (
        <img src="/assets/media/credit-card.png" style={{ width: "30px" }} />
      ),
      color: "#527ca9",
    },
    {
      type: "ACoS Increasing by Higher Margin",
      key_name: "acos_increasing_by_higher_margin",
      icon: <img src="/assets/media/increase.png" style={{ width: "30px" }} />,
      color: "#f2235f",
    },
    {
      type: "Getting Negative Product Reviews",
      key_name: "negative_product_review",
      icon: <img src="/assets/media/criticism.png" style={{ width: "30px" }} />,
      color: "#4b01e0",
    },
    {
      type: "Negative Seller Feedbacks",
      key_name: "negative_seller_feedbacks",
      icon: <img src="/assets/media/sad.png" style={{ width: "30px" }} />,
      color: "#fc5a15",
    },
    {
      type: "Advertising Performance Reduced (Trend)",
      key_name: "adv_performance_reduced",
      icon: (
        <img
          src="/assets/media/increase_decrease.png"
          style={{ width: "30px", transform: "rotate(90deg)" }}
        />
      ),
      color: "#956aa9",
    },
    {
      type: "Product Issue Duration (OOS, Suppressed, Blocked, Stranded)",
      key_name: "product_issue_duration",
      icon: <img src="/assets/media/time.png" style={{ width: "30px" }} />,
      color: "#84c64a",
    },
    {
      type: "Sales Price Changes",
      key_name: "sales_price_changes",
      icon: <img src="/assets/media/coin.png" style={{ width: "30px" }} />,
      color: "#f2235f",
    },
  ];

  const loadingArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                  <div className="position-relative">
                    <h3 class="card-title align-items-start flex-column">
                      <span class="card-label fw-bold fs-3 mb-1">
                        Recommendation & Alerts
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div class="d-flex flex-stack flex-wrap gap-4">
                    <div className="position-relative me-2">
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Select Marketplace"
                        options={GetMarketplaceList(marketplace_list)}
                        value={selected_marketplace}
                        onChange={(e) => {
                          set_selected_marketplace(e);
                          set_selected_currency_sign(currency_list[e]);
                          setLoading(true);
                          GetAlertsList(e);
                        }}
                      />
                    </div>
                    <div class="position-relative">
                      {/* <Input
                        prefix={
                          <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                        }
                        placeholder="Search by ASIN or SKU"
                        size="large"
                        value={all_filters?.search}
                        onChange={(e) => {
                          set_all_filters({
                            ...all_filters,
                            search: e.target.value,
                          });
                        }}
                        onPressEnter={(e) => {
                          const apiObj = {
                            ...all_filters,
                            search: e.target.value,
                          };
                          setLoading(true);
                          GetCentralLogList(apiObj);
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/* <div className="row d-flex flex-wrap justify-content-around">
                  {data?.map((d) => {
                    return (
                      <div
                        className="mb-4 "
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "10px",
                          width: "350px",
                        }}
                      >
                        <div className="p-5">
                          <div className="d-flex">
                            <div className="symbol symbol-50px me-4">
                              <div
                                className="symbol-label bg-transparent rounded-lg"
                                style={{
                                  border: `1px dashed ${d?.color || "#3699ff"}`,
                                }}
                              >
                                {d?.icon}
                              </div>
                            </div>
                            <div>
                              <div>
                                
                                <span
                                  className="fw-bold fs-5 text-dark"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {d?.type}
                                </span>
                              </div>
                              <div>
                                <span className="fs-1 fw-bold text-dark me-2">
                                  {d?.value}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="my-2"
                            style={{ borderBottom: "1px solid #e8e8e8" }}
                          ></div>
                          <div className="change">
                            Total Products :
                            <label className="fw-bold" style={{ color: "red" }}>
                              10
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div> */}

                <div className="separator separator-dashed mb-3" />
                <div className="mt-5">
                  {loading
                    ? loadingArr.map((d) => {
                        return (
                          <>
                            <div className="row mb-5">
                              <Skeleton.Button
                                active
                                block
                                style={{ height: "50px" }}
                              />
                            </div>
                          </>
                        );
                      })
                    : data?.map((d) => {
                        return (
                          <>
                            <div
                              className="row cursor-pointer"
                              onClick={() => {
                                if (alert_list?.[d?.key_name] > 0) {
                                  const apiObj = {
                                    ...product_list_filter,
                                    type: d?.key_name,
                                  };
                                  setselectedType(d?.key_name);
                                  set_product_list_filter(apiObj);
                                  setIsProductListLoading(true);
                                  message.destroy();
                                  message.loading("Loading...", 0);
                                  GetProductList(apiObj);
                                } else {
                                  message.destroy();
                                  message.info("No Products Found");
                                }
                              }}
                              style={{
                                pointerEvents: isProductListLoading
                                  ? "none"
                                  : "auto",
                              }}
                            >
                              <div className="col-6 d-flex align-items-center">
                                <div
                                  className="icon"
                                  style={{ color: d?.color }}
                                >
                                  <div className="symbol symbol-50px me-4">
                                    <div
                                      className="symbol-label bg-transparent rounded-lg"
                                      style={{
                                        border: `1px dashed ${
                                          d?.color || "#3699ff"
                                        }`,
                                      }}
                                    >
                                      {d?.icon}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="popoverActionIconTwoLine fs-4"
                                  style={{
                                    wordBreak: "break-word",
                                    fontWeight: "500",
                                    color: "#575757",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {d?.type}
                                </div>
                              </div>
                              <div className="col-6 justify-content-center d-flex align-items-center">
                                <div
                                  className="value fs-4"
                                  style={{
                                    background: alert_list?.[d?.key_name]
                                      ? "#e7e3e3"
                                      : "",
                                    padding: alert_list?.[d?.key_name]
                                      ? "4px 10px"
                                      : "",
                                    borderRadius: alert_list?.[d?.key_name]
                                      ? "4px"
                                      : "",
                                  }}
                                >
                                  {alert_list?.[d?.key_name] || 0}
                                </div>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-3" />
                          </>
                        );
                      })}
                </div>

                {/* <RecommendationStyle>
                  <div className="row">
                    {loading
                      ? loadingArr.map((d) => {
                          return (
                            <>
                              <div
                                style={{ width: "285px" }}
                                className="my-4 mx-2"
                              >
                                <Skeleton.Button
                                  active
                                  block
                                  style={{ height: "130px" }}
                                />
                              </div>
                            </>
                          );
                        })
                      : data.map(
                          (item) =>
                            alert_list?.[item?.key_name] > 0 && (
                              <>
                                <div className="col-6 col-md-4 col-lg-3 col-xl-3 mb-5">
                                  <div
                                    onClick={() => {
                                      if (alert_list?.[item?.key_name] > 0) {
                                        const apiObj = {
                                          ...product_list_filter,
                                          type: item?.key_name,
                                        };
                                        set_product_list_filter(apiObj);
                                        setIsProductListLoading(true);
                                        message.destroy();
                                        message.loading("Loading...", 0);
                                        GetProductList(apiObj);
                                      }
                                    }}
                                    className={`card ${item?.type} ${item?.key_name}`}
                                    style={{
                                      pointerEvents: isProductListLoading
                                        ? "none"
                                        : "auto",
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-start fs-4"
                                      style={{
                                        height: "50px",
                                        width: "100%",
                                        fontWeight: "500",
                                        color: "#575757",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <Tooltip title={item?.type}>
                                        <span
                                          className="popoverActionIconTwoLine"
                                          style={{ wordBreak: "break-word" }}
                                        >
                                          {item?.type}
                                        </span>
                                      </Tooltip>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-between"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <div className="value">
                                        {alert_list?.[item?.key_name] || 0}
                                      </div>
                                      <div
                                        className="icon"
                                        style={{ color: item?.color }}
                                      >
                                        <div className="symbol symbol-50px me-4">
                                          <div
                                            className="symbol-label bg-transparent rounded-lg"
                                            style={{
                                              border: `1px dashed ${
                                                item?.color || "#3699ff"
                                              }`,
                                            }}
                                          >
                                            {item?.icon}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        )}
                  </div>
                </RecommendationStyle> */}
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
      {isOpenModal && (
        <ProductListModal
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
            setselectedType("");
          }}
          data={allProductList}
          all_filters={product_list_filter}
          set_all_filters={set_product_list_filter}
          GetProductList={GetProductList}
          totalPage={totalPage}
          loading={isProductListLoading}
          setLoading={setIsProductListLoading}
          selectedType={selectedType}
        />
      )}
    </>
  );
};

export default RecommendationAndAlerts;
