import React from 'react'
import { Modal, Table, Tag } from "antd";


function ModalData({ modalOpen, setModalOpen }) {


    const dataSource = [
        {
          key: '1',
          metric: 'Product Title (Length: 183)',
          obtainedScore: 6,
          maxScore: 6,
        },
        {
          key: '2',
          metric: 'Product Title (separators: ,, -)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '3',
          metric: 'Product Title (first_letter_capital)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '4',
          metric: 'Product Title (relevant_keyword)',
          obtainedScore: 0,
          maxScore: 4,
        },
        {
          key: '5',
          metric: 'Product Title (brand_name)',
          obtainedScore: 6,
          maxScore: 6,
        },
        {
          key: '6',
          metric: 'Bullet Point 1 (Length: 539)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '7',
          metric: 'Bullet Point 2 (Length: 460)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '8',
          metric: 'Bullet Point 3 (Length: 465)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '9',
          metric: 'Bullet Point 4 (Length: 469)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '10',
          metric: 'Bullet Point 5 (Length: 376)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '11',
          metric: 'Bullet Point 1 (headers)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '12',
          metric: 'Bullet Point 1 (relevant_keyword)',
          obtainedScore: 0,
          maxScore: 4,
        },
        {
          key: '13',
          metric: 'Bullet Point 2 (headers)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '14',
          metric: 'Bullet Point 2 (relevant_keyword)',
          obtainedScore: 0,
          maxScore: 4,
        },
        {
          key: '15',
          metric: 'Bullet Point 3 (headers)',
          obtainedScore: 4,
          maxScore: 4,
        },
        {
          key: '16',
          metric: 'Bullet Point 3 (relevant_keyword)',
          obtainedScore: 0,
          maxScore: 4,
        },
      ];
      
      const columns_modal = [
        {
          title: 'Metric',
          dataIndex: 'metric',
          key: 'metric',
          className: 'min-w-200px'
        },
        {
          title: 'Obtained Score',
          dataIndex: 'obtainedScore',
          key: 'obtainedScore',
          className: 'min-w-125px text-center',
          render: (_, record) => (
            <>
              <Tag color={record.obtainedScore > 0 ? 'green' : 'red'} className="fs-6 fw-bold py-1">{record.obtainedScore}</Tag>
            </>
          ),
        },
        {
          title: 'Max Score',
          dataIndex: 'maxScore',
          key: 'maxScore',
          className: 'min-w-125px text-center',
          render: (_, record) => (
            <>
              <Tag color={record.maxScore > 50 ? 'blue' : 'blue'} className="fs-6 fw-bold py-1">{record.maxScore}</Tag>
            </>
          ),
        },
      ];
     // Calculate the total scores dynamically
     const totalObtainedScore = dataSource.reduce((sum, record) => sum + record.obtainedScore, 0);
     const totalMaxScore = dataSource.reduce((sum, record) => sum + record.maxScore, 0);
    
     // Add the total row dynamically
     const dataWithTotal = [
       ...dataSource,
       {
         key: 'total',
         metric: 'Total LQS Score',
         obtainedScore: totalObtainedScore,
         maxScore: totalMaxScore,
       },
     ];


  return (
    <Modal title="LQS Score" centered open={modalOpen} onCancel={() => setModalOpen(false)} width={700}>
      <Table className="cus-table" dataSource={dataWithTotal} columns={columns_modal} scroll={{ x: 'max-content', y: 500 }} pagination={false} />
    </Modal>
  )
}

export default ModalData