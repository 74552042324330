import { GetLinks } from "../../../../core";

export default [
  {
    key: "/data-scheduler",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-watch fs-1"
          // style={{ color: "#FF0000" }}
        ></i>
      </span>
    ),
    label: GetLinks("/data-scheduler", "Data Scheduler"),
  },
];
