import React, { useContext, useEffect, useState } from "react";
import KeywordSearchTerm from "./lib/keyword-searchterm-targeting";
import NegativeKeywordTargeting from "./lib/negative-keyword-targeting";
import { GlobalContext } from "../../../../commonContext";
import {
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
} from "../../../../config";
import { Select } from "antd";

const KeywordTargeting = () => {
  const [mode, setMode] = useState("keyword-targeting");
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetMarketplace();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetMarketplace();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
    } else {
      setLoading(false);
    }
  };
  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="row mb-4 px-0 mx-0">
        <div
          className="col-12 col-md-8 px-0 mx-0"
          style={{ borderBottom: "1px solid #3699ff" }}
        >
          <button
            className="btn btn-sm mx-2 px-7 fs-6"
            style={{
              background: "#3699ff0f",
              borderRadius: " 20px 20px 0px 0px",
              color: "#0056af",
              borderColor:
                mode === "keyword-targeting"
                  ? "#3699ffc2"
                  : "rgba(230, 51, 42, 0.09)",
              borderStyle: "solid",
              borderWidth: "1px 1px 0px 1px",
            }}
            onClick={() => {
              setMode("keyword-targeting");
              setLoading(true);
            }}
          >
            Search term as keyword
          </button>
          <button
            className="btn btn-sm mx-2 px-7 fs-6"
            style={{
              background: "#3699ff0f",
              borderRadius: "20px 20px 0px 0px",
              color: "#0056af",
              borderColor:
                mode === "negative-keyword-targeting"
                  ? "#3699ffc2"
                  : "rgba(230, 51, 42, 0.09)",
              borderStyle: "solid",
              borderWidth: "1px 1px 0px 1px",
            }}
            onClick={() => {
              setMode("negative-keyword-targeting");
              setLoading(true);
            }}
          >
            Negative keyword targeting
          </button>
        </div>
        <div className="col-12 col-md-4 d-flex justify-content-end">
          <div className="position-relative me-2">
            <Select
              className="w-200px"
              size="large"
              placeholder="Select Marketplace"
              options={GetMarketplaceList(marketplace_list)}
              value={selected_marketplace}
              onChange={(e) => {
                set_selected_marketplace(e);
                set_selected_currency_sign(currency_list[e]);
                setLoading(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {mode === "keyword-targeting" && (
          <KeywordSearchTerm
            loading={loading}
            setLoading={setLoading}
            selected_marketplace={selected_marketplace}
            marketplace_list={marketplace_list}
            selected_currency_sign={selected_currency_sign}
          />
        )}
      </div>
      <div className="row">
        {mode === "negative-keyword-targeting" && (
          <NegativeKeywordTargeting
            loading={loading}
            setLoading={setLoading}
            selected_marketplace={selected_marketplace}
            marketplace_list={marketplace_list}
            selected_currency_sign={selected_currency_sign}
          />
        )}
      </div>
    </div>
  );
};

export default KeywordTargeting;
