import { Checkbox, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../../../apis/make-api-call";
import { DisplayNotification } from "../../../../../../config";
export default function ({ show, onHide, user_list, selected_rows }) {
  const [sending, setSending] = useState(false);
  const [fieldData, setFieldData] = useState({
    is_all_user: 0,
  });

  const onSaveDataScheduler = async () => {
    message.destroy();
    message.loading("Loading", 0);
    const response = await MakeApiCall(
      `admin-data-scheduler`,
      "put",
      { ...fieldData, admin_scheduler_ids: selected_rows },
      true,
      false
    );
    if (response?.status) {
      setSending(false);
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
      onHide();
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
      setSending(false);
    }
  };

  const setFieldDataValue = (key, value) => {
    const common_data = {
      ...fieldData,
      [key]: value,
    };
    setFieldData(common_data);
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Admin Scheduler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              {" "}
              <div className="col-4 mb-5">
                <label className="fs-6 fw-bold mb-2">Select All User</label>
                <br />
                <Checkbox
                  size="large"
                  onChange={(e) => {
                    if (e.target.checked) {
                      const allRows = user_list?.map((d) => d?.id);
                      const common_data = {
                        ...fieldData,
                        user_ids: allRows,
                        is_all_user: 1,
                      };
                      setFieldData(common_data);
                    } else {
                      const common_data = {
                        ...fieldData,
                        user_ids: [],
                        is_all_user: 0,
                      };
                      setFieldData(common_data);
                    }
                  }}
                />
              </div>
              <div className="col-4 mb-5">
                <label className="fs-6 fw-bold mb-2">Users</label>
                <Select
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Users"
                  allowClear
                  disabled={fieldData?.is_all_user === 1 ? true : false}
                  value={fieldData?.user_ids || null}
                  onChange={(e) => {
                    setFieldDataValue("user_ids", e);
                  }}
                  maxTagCount={"responsive"}
                  mode={"multiple"}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={user_list?.map((d) => {
                    return {
                      label: <span>{d?.name}</span>,
                      value: d?.id,
                    };
                  })}
                />
              </div>
              <div className="col-4 mb-5">
                <label className="fs-6 fw-bold mb-2">Previous Days</label>
                <Input
                  type="text"
                  className="ant_common_input"
                  size="large"
                  value={fieldData?.previous_days}
                  onChange={(e) => {
                    setFieldDataValue("previous_days", e.target.value);
                  }}
                />
              </div>
              <div className="col-4 mb-5">
                <label className="fs-6 fw-bold mb-2">Update Daily Days</label>
                <Input
                  type="text"
                  className="ant_common_input"
                  size="large"
                  value={fieldData?.update_daily_days}
                  onChange={(e) => {
                    setFieldDataValue("update_daily_days", e.target.value);
                  }}
                />
              </div>
              <div className="col-4 mb-5">
                <label className="fs-6 fw-bold mb-2">Group by Days</label>
                <Input
                  type="text"
                  className="ant_common_input"
                  size="large"
                  value={fieldData?.groupby_days}
                  onChange={(e) => {
                    setFieldDataValue("groupby_days", e.target.value);
                  }}
                />
              </div>
              <div className="col-4 mb-5">
                <label className="fs-6 fw-bold mb-2">
                  Current Day Frequency
                </label>
                <Input
                  type="text"
                  className="ant_common_input"
                  size="large"
                  value={fieldData?.current_day_hr_frequency}
                  onChange={(e) => {
                    setFieldDataValue(
                      "current_day_hr_frequency",
                      e.target.value
                    );
                  }}
                />
              </div>
              <div className="col-4 mb-5">
                <label className="fs-6 fw-bold mb-2">
                  Current Day Delay Frequency
                </label>
                <Input
                  type="text"
                  className="ant_common_input"
                  size="large"
                  value={fieldData?.current_day_delay_hr_frequency}
                  onChange={(e) => {
                    setFieldDataValue(
                      "current_day_delay_hr_frequency",
                      e.target.value
                    );
                  }}
                />
              </div>
              <div className="col-4 mb-5">
                <label className="fs-6 fw-bold mb-2">Status</label>
                <Select
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Status"
                  allowClear
                  value={fieldData?.status || null}
                  onChange={(e) => {
                    setFieldDataValue("status", e);
                  }}
                  maxTagCount={"responsive"}
                  mode={"multiple"}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={[
                    { label: "Active", value: 1 },
                    { label: "Inactive", value: 0 },
                  ]}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary fs-7"
            disabled={
              !fieldData?.previous_days &&
              !fieldData?.update_daily_days &&
              !fieldData?.groupby_days &&
              !fieldData?.current_day_hr_frequency &&
              !fieldData?.current_day_delay_hr_frequency &&
              !fieldData?.status
            }
            onClick={() => {
              if (
                fieldData?.previous_days ||
                fieldData?.update_daily_days ||
                fieldData?.groupby_days ||
                fieldData?.current_day_hr_frequency ||
                fieldData?.current_day_delay_hr_frequency ||
                fieldData?.status
              ) {
                setSending(true);
                onSaveDataScheduler();
              }
            }}
          >
            Save
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
