import { Button, Input, message, Popconfirm, Tag } from "antd";
import React, { useEffect, useState } from "react";
import EsfTable from "../../../../component/esf-table";
import EmailFormModal from "./add";
import { MakeApiCall } from "../../../../apis/make-api-call";
import Pagination from "../../../../component/pagination";

const EmailDefination = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingData, setEditingData] = useState(null);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchKey, setSearchKey] = useState("");

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const getList = async (data) => {
    const response = await MakeApiCall(
      `email-template?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&search_key=${data?.searchKey || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    return () => {};
  }, []);

  // Open modal for adding new data
  const handleAddClick = () => {
    setEditingData(null);
    setModalVisible(true);
  };

  // Open modal for editing existing data
  const handleEditClick = (data) => {
    setEditingData(data);
    setModalVisible(true);
  };

  const addRow = async (data) => {
    const response = await MakeApiCall(`email-template`, "post", data, true);

    if (response?.status) {
      setPage(1);
      getList({ page: 1 });
      setEditingData(null);
      setModalVisible(false);
    } else {
      message.warning(response?.message);
    }
  };
  const editRow = async (data) => {
    const response = await MakeApiCall(
      `email-template/${editingData?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      setPage(1);
      getList({ page: 1 });
      setEditingData(null);
      setModalVisible(false);
    } else {
      message.warning(response?.message);
    }
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    if (editingData) {
      editRow(data);
    } else {
      addRow(data);
    }
  };

  const columns = [
    {
      title: "Sender Email Address",
      dataIndex: "sender_email_address",
      filterIndex: "sender_email_address",
      render: (email) => <strong>{email}</strong>,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      filterIndex: "slug",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      filterIndex: "subject",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      filterIndex: "tags",
      render: (e) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 260,
            }}
          >
            {e}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="d-flex flex-shrink-0 justify-content-center">
          <span
            onClick={() => handleEditClick(record)}
            className="btn btn-icon bg-light btn-active-color-success btn-sm me-2"
          >
            <i className="ki-outline ki-pencil fs-2 text-success" />
          </span>
          <span
            onClick={() => handleEditClick({ ...record, type: "view" })}
            className="btn btn-icon bg-light btn-active-color-success btn-sm me-2"
          >
            <i className="ki-outline ki-eye fs-2 text-primary">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </i>
          </span>
        </div>
      ),
    },
  ];

  const ClearSearch = async () => {
    setLoading(true);
    setSearchKey("");
    getList({ searchKey: "" });
  };

  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="card card-xxl-stretch mb-5 mb-xl-8">
        <div className="card-header border-0">
          <div className="d-flex flex-stack flex-wrap gap-4 p-2">
            <div className="position-relative">
              <Input
                prefix={
                  <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                }
                onChange={(e) => {
                  setSearchKey((prev) => {
                    if (!e.target.value && prev) {
                      ClearSearch();
                    }
                    return e.target.value;
                  });
                }}
                onPressEnter={() => {
                  setLoading(true);
                  getList({ searchKey: searchKey });
                }}
                value={searchKey}
                placeholder="Search..."
                size="large"
              />
            </div>
          </div>
          <div className="card-toolbar">
            <div class="d-flex flex-stack flex-wrap gap-4">
              <Button onClick={handleAddClick} size="large" type="primary">
                + Add
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body py-2">
          <EsfTable
            columns={columns}
            pagination={false}
            dataSource={list}
            loading={loading}
            scroll={{ x: "max-content" }}
          />
        </div>
        <div className="card-footer pt-0 pb-5">
          <Pagination
            loading={loading}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={(e) => {
              setLoading(true);
              setPageSize(e);
              setPage(1);
              getList({
                page: 1,
                pageSize: e,
                searchKey: searchKey,
              });
            }}
            onPageNo={(e) => {
              setLoading(true);
              setPage(e);
              getList({
                page: e,
                searchKey: searchKey,
              });
            }}
          />
        </div>
      </div>
      {isModalVisible && (
        <EmailFormModal
          visible={isModalVisible}
          onClose={() => {
            setModalVisible(false);
            setEditingData(null);
          }}
          onSubmit={handleFormSubmit}
          initialValues={editingData}
        />
      )}
    </div>
  );
};

export default EmailDefination;
