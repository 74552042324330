import { Button, Input, message, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Table2 from "../../../../component/antd-table/table2";
import EsfTable from "../../../../component/esf-table";
import { MakeApiCall } from "../../../../apis/make-api-call";

// import { MakeApiCall } from "../../../../apis";

const { TabPane } = Tabs;

const AdDefinitions = () => {
  const [editRow, setEditRow] = useState({});

  const [list, setList] = useState({
    SPONSORED_BRANDS: {
      Medium: [
        {
          id: 3,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Medium",
          ad_type_value: "STORE_SPOTLIGHT",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 29,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Medium",
          ad_type_value: "PRODUCT_COLLECTION",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1729308603,
          updated_at: 1729308603,
          status: 1,
        },
      ],
      Targeting: [
        {
          id: 9,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Targeting",
          ad_type_value: "AUTO",
          mapped_ad_type: "Auto",
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728553742,
          status: 1,
        },
      ],
      Destination: [
        {
          id: 16,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Destination",
          ad_type_value: "STORE",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 33,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Destination",
          ad_type_value: "PRODUCT_LIST",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1729308603,
          updated_at: 1729308603,
          status: 1,
        },
      ],
      Placement: [
        {
          id: 21,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Placement",
          ad_type_value: "Detail Page on-Amazon",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 22,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Placement",
          ad_type_value: "Off Amazon",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 23,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Placement",
          ad_type_value: "Other on-Amazon",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 24,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Placement",
          ad_type_value: "Top of Search on-Amazon",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
      ],
    },
    SPONSORED_DISPLAY: {
      Medium: [
        {
          id: 5,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Medium",
          ad_type_value: "IMAGE",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 7,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Medium",
          ad_type_value: "PRODUCT_AD",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
      ],
      Targeting: [
        {
          id: 10,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Targeting",
          ad_type_value: "PRODUCT_AUDIENCE",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 11,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Targeting",
          ad_type_value: "PRODUCT_CATEGORY_AUDIENCE",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 12,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Targeting",
          ad_type_value: "PRODUCT_CATEGORY",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 30,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Targeting",
          ad_type_value: "PRODUCT",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1729308603,
          updated_at: 1729308603,
          status: 1,
        },
        {
          id: 31,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Targeting",
          ad_type_value: "AUTO",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1729308603,
          updated_at: 1729308603,
          status: 1,
        },
      ],
      Destination: [
        {
          id: 32,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Destination",
          ad_type_value: "STORE",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1729308603,
          updated_at: 1729308603,
          status: 1,
        },
      ],
    },
    SPONSORED_PRODUCTS: {
      Targeting: [
        {
          id: 13,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Targeting",
          ad_type_value: "AUTO",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 14,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Targeting",
          ad_type_value: "PRODUCT",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 15,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Targeting",
          ad_type_value: "KEYWORD",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
      ],
      Destination: [
        {
          id: 19,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Destination",
          ad_type_value: "DETAIL_PAGE",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
      ],
      Placement: [
        {
          id: 25,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Placement",
          ad_type_value: "Detail Page on-Amazon",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 26,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Placement",
          ad_type_value: "Off Amazon",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 27,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Placement",
          ad_type_value: "Other on-Amazon",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
        {
          id: 28,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Placement",
          ad_type_value: "Top of Search on-Amazon",
          mapped_ad_type: null,
          is_sync: 0,
          created_at: 1728552782,
          updated_at: 1728552782,
          status: 1,
        },
      ],
    },
  });
  const [loading, setLoading] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  const [syncList, setSyncList] = useState({
    SPONSORED_BRANDS: {
      Medium: [
        {
          id: 1,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Medium",
          ad_type_value: "BRAND_VIDEO",
          mapped_ad_type: "Brand Video",
          is_sync: 1,
          created_at: 1728552782,
          updated_at: 1729492597,
          status: 1,
        },
        {
          id: 2,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Medium",
          ad_type_value: "VIDEO",
          mapped_ad_type: "Video",
          is_sync: 1,
          created_at: 1728552782,
          updated_at: 1728885702,
          status: 1,
        },
      ],
      Targeting: [
        {
          id: 8,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Targeting",
          ad_type_value: "KEYWORD",
          mapped_ad_type: "Keyword",
          is_sync: 1,
          created_at: 1728552782,
          updated_at: 1728890274,
          status: 1,
        },
      ],
      Destination: [
        {
          id: 17,
          ad_product: "SPONSORED_BRANDS",
          ad_type: "Destination",
          ad_type_value: "DETAIL_PAGE",
          mapped_ad_type: "PDP",
          is_sync: 1,
          created_at: 1728552782,
          updated_at: 1728553121,
          status: 1,
        },
      ],
    },
    SPONSORED_PRODUCTS: {
      Medium: [
        {
          id: 4,
          ad_product: "SPONSORED_PRODUCTS",
          ad_type: "Medium",
          ad_type_value: "PRODUCT_AD",
          mapped_ad_type: "Product AD",
          is_sync: 1,
          created_at: 1728552782,
          updated_at: 1728883917,
          status: 1,
        },
      ],
    },
    SPONSORED_DISPLAY: {
      Medium: [
        {
          id: 6,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Medium",
          ad_type_value: "VIDEO",
          mapped_ad_type: "VIDEO",
          is_sync: 1,
          created_at: 1728552782,
          updated_at: 1728886793,
          status: 1,
        },
      ],
      Destination: [
        {
          id: 20,
          ad_product: "SPONSORED_DISPLAY",
          ad_type: "Destination",
          ad_type_value: "DETAIL_PAGE",
          mapped_ad_type: "PDP",
          is_sync: 1,
          created_at: 1728552782,
          updated_at: 1728899968,
          status: 1,
        },
      ],
    },
  });
  const [syncLoading, setSyncLoading] = useState(false);

  const adDefinationData = async (data) => {
    const response = await MakeApiCall(
      `ad-definition?is_sync=0`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      setLoading(false);
      message.warning(response?.message);
    }
  };
  const SyncAdDefinationData = async (data) => {
    const response = await MakeApiCall(
      `ad-definition?is_sync=1`,
      "get",
      null,
      true
    );
    if (response?.status) {
      console.log(response, "response");
      setSyncList(response?.data?.records || []);
      setSyncLoading(false);
    } else {
      setSyncLoading(false);
      setSyncList([]);
      message.warning(response?.message);
    }
  };

  const UpdateadDefinationData = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `ad-definition/${data?.id}`,
      "put",
      {
        mapped_ad_type: data?.mapped_ad_type,
        is_sync: 0,
      },
      true
    );
    if (response?.status) {
      adDefinationData();
      setEditRow("");
      setSelectedID(null);
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const SyncUpdateadDefinationData = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `ad-definition/sync/${id}`,
      "put",
      {
        is_sync: 1,
      },
      true
    );
    if (response?.status) {
      adDefinationData();
      setEditRow("");
      setSyncLoading(true);
      SyncAdDefinationData();
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const updateMappedAdType = (adProduct, adType, id, newMappedAdType) => {
    setList((currentData) => {
      return {
        ...currentData,
        [adProduct]: {
          ...currentData[adProduct],
          [adType]: currentData[adProduct][adType].map((item) => {
            if (item.id === id) {
              return {
                ...item,
                mapped_ad_type: newMappedAdType,
                isAPI: false,
                edit: true,
              };
            }
            return item;
          }),
        },
      };
    });
  };

  const columns = [
    {
      title: "DB Name",
      dataIndex: "ad_type_value",
      key: "ad_type_value",
      filterIndex: "ad_type_value",
      width: 300,
    },
    {
      title: "New Name",
      width: 300,
      filterIndex: "mapped_ad_type",
      render: (d) => {
        if (d?.is_sync === 0) {
          return (
            <Input
              className="w-150px me-3"
              placeholder="Enter New Name"
              value={d?.mapped_ad_type}
              disabled={d?.id === selectedID ? false : true}
              onChange={(e) => {
                updateMappedAdType(
                  d?.ad_product,
                  d?.ad_type,
                  d?.id,
                  e.target.value
                );

                setEditRow({
                  ...editRow,
                  mapped_ad_type: e.target.value,
                });
              }}
            />
          );
        }
        return (
          <div>
            <span className="me-3">{d?.mapped_ad_type}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 350,
      render: (d) => {
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-start gap-3">
              {d?.is_sync === 0 && (
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedID(d?.id);
                      if (selectedID) {
                        UpdateadDefinationData(d);
                      }
                    }}
                    className="add-btn"
                  >
                    {selectedID ? "Save" : "Edit"}
                  </Button>
                </>
              )}
              {d?.mapped_ad_type && d?.is_sync === 0 && !d?.edit && (
                <svg
                  onClick={() => {
                    SyncUpdateadDefinationData(d?.id);
                  }}
                  width={18}
                  height={18}
                  fill="#000"
                  className="me-3 cursor-pointer"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="m3.009 12.403-1.839 1.85L0 13.098l3.135-3.105 1.14.015 3.135 3.18-1.14 1.14-1.75-1.77a7.5 7.5 0 0 0 14.1 2.974l1.219.896a9 9 0 0 1-16.83-4.025Zm16.485-.699L17.64 9.828l-1.14 1.14 3.135 3.165 1.14.015 3.135-3.105-1.125-1.14-1.791 1.77a9 9 0 0 0-16.665-4.38l1.215.89a7.5 7.5 0 0 1 13.95 3.52v.001Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>
          </div>
        );
      },
    },
  ];
  const columns_edit = [
    {
      title: "DB Name",
      dataIndex: "ad_type_value",
      key: "ad_type_value",
      filterIndex: "ad_type_value",
      width: 300,
    },
    {
      title: "New Name",
      filterIndex: "mapped_ad_type",
      width: 300,
      render: (d) => {
        if (d?.is_sync === 0) {
          return (
            <Input
              className="w-150px me-3"
              placeholder="Enter New Name"
              value={d?.mapped_ad_type}
              disabled={d?.id === selectedID ? false : true}
              onChange={(e) => {
                updateMappedAdType(
                  d?.ad_product,
                  d?.ad_type,
                  d?.id,
                  e.target.value
                );

                setEditRow({
                  ...editRow,
                  mapped_ad_type: e.target.value,
                });
              }}
            />
          );
        }
        return (
          <div>
            <span className="me-3">{d?.mapped_ad_type}</span>
          </div>
        );
      },
    },
  ];

  const MarketingTabs = (props, columns, title, loading) => {
    return (
      <div className="card">
        <div className="card-header">
          <div className="card-title">{title}</div>
        </div>
        <div className="card-body pb-0 pt-2">
          <Tabs defaultActiveKey="0">
            {Object.entries(props || {})?.map(([key, value], i) => (
              <TabPane
                tab={
                  <span style={{ textTransform: "capitalize" }}>
                    {key?.replace("_", " ")?.toLocaleLowerCase()}
                  </span>
                }
                className="pt-5"
                key={i?.toString()}
              >
                <EsfTable
                  scroll={{ x: "max-content" }}
                  pagination={{
                    size: "default",
                    showSizeChanger: true,
                  }}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                  columns={columns?.map((d) => ({
                    ...d,
                    className: "minWidth-table",
                  }))}
                  loading={loading}
                  dataSource={value}
                />
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    );
  };

  return (
    <div id="kt_content_container" className="custom-ui container-fluid">
      <div className="row g-2 mt-2">
        <div className="col-xxl-12">
          <Tabs
            tabBarStyle={{ marginBottom: 0 }}
            defaultActiveKey="1"
            type="card"
          >
            {Object.entries(list || {})?.map(([key, value], i) => (
              <TabPane
                tab={
                  <span style={{ textTransform: "capitalize" }}>
                    {key?.replace("_", " ")?.toLocaleLowerCase()}
                  </span>
                }
                key={i?.toString()}
              >
                {MarketingTabs(value, columns, "Pending Definitions", loading)}
              </TabPane>
            ))}
          </Tabs>
        </div>
        <div className="col-xxl-12 mt-5">
          <Tabs
            tabBarStyle={{ marginBottom: 0 }}
            defaultActiveKey="1"
            type="card"
          >
            {Object.entries(syncList || {})?.map(([key, value], i) => (
              <TabPane
                tab={
                  <span style={{ textTransform: "capitalize" }}>
                    {key?.replace("_", " ")?.toLocaleLowerCase()}
                  </span>
                }
                key={i?.toString()}
              >
                {MarketingTabs(
                  value,
                  columns_edit,
                  "Mapped Definitions",
                  syncLoading
                )}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default AdDefinitions;
