import Dayparting from "../modules/pages/day-parting/day-parting";
import RuleBook from "../modules/pages/rule-book/rule-book";

export default [
  {
    path: "/rule-book",
    component: <RuleBook />,
    exact: true,
  },
  {
    path: "/day-parting",
    component: <Dayparting />,
    exact: true,
  },
  // {
  //   path: "/keyword-management/keyword",
  //   component: <Keywords />,
  //   exact: true,
  // },
];
