// import Icons from "../../../../components/icons";

import { GetLinks } from "../../../../core";

export default [
  {
    key: "/reimbursement",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-dollar fs-1"
          // style={{ color: "#FF00FF" }}
        ></i>
      </span>
    ),
    label: "Reimbursement",
    children: [
      {
        key: "/reimbursement-dashboard",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/reimbursement-dashboard", "Dashboard"),
      },
      {
        key: "/details",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/details", "Reimbursement Detail"),
      },
      {
        key: "/manager",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/manager", "Manager"),
      },
    ],
  },
];
