import AnalyticsDashobard from "../modules/pages/analytics/dashboard";
import PLStatement from "../modules/pages/analytics/p&l-statement";
import ProductAnalytics from "../modules/pages/analytics/product-analytics";

export default [
  {
    path: "/analytics/dashboard",
    component: <AnalyticsDashobard />,
    exact: true,
  },
  {
    path: "/analytics/p&l-statement",
    component: <PLStatement />,
    exact: true,
  },
  {
    path: "/analytics/product-analytics",
    component: <ProductAnalytics />,
    exact: true,
  },
];
