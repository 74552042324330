// import Icons from "../../../../components/icons";

import { GetLinks } from "../../../../core";

// export default [
//   {
//     key: "/admin-application-logs",
//     icon: (
//       <span class="menu-icon">
//         <i
//           class="ki-outline ki-chart-simple-2 fs-1"
//           // style={{ color: "#afa019" }}
//         ></i>
//       </span>
//     ),
//     label: "Application Logs",
//     children: [
//       //   {
//       //     key: "/application-logs/central-log",
//       //     icon: (
//       //       <span className="menu-bullet">
//       //         <span className="bullet bullet-dot" />
//       //       </span>
//       //     ),
//       //     label: GetLinks("/application-logs/central-log", "Central Log"),
//       //   },
//       //   {
//       //     key: "/application-logs/system-error-log",
//       //     icon: (
//       //       <span className="menu-bullet">
//       //         <span className="bullet bullet-dot" />
//       //       </span>
//       //     ),
//       //     label: GetLinks(
//       //       "/application-logs/system-error-log",
//       //       "System Error Log"
//       //     ),
//       //   },
//       {
//         key: "/admin-application-logs/data-scheduler",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks(
//           "/admin-application-logs/data-scheduler",
//           "Data Scheduler"
//         ),
//       },
//     ],
//   },
// ];

export default [
  {
    key: "/admin-data-scheduler",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-watch fs-1"
          // style={{ color: "#FF0000" }}
        ></i>
      </span>
    ),
    label: GetLinks("/admin-data-scheduler", "Data Scheduler"),
  },
];
