import { Button, DatePicker, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Wrapper } from "./style";

const PLStatement = () => {
  const obj = {
    sales: "currency",
    tax: "currency",
    fba_fees: "currency",
    additional_charges: "currency",
    promotions: "currency",
    shipping_charge: "currency",
    refunds: "",
    total_cogs: "currency",
    total_profit: "currency",
    profit_margin: "pr",
    total_roi: "pr",
    units_sold: "",
    PPC_costs: "currency",
    fba_liquidation: "currency",
    safe_t_reimbursement: "currency",
    adjustements: "currency",
    removal_shipment_adjustment_quantity: "",
    adhoc_disbursement: "currency",
    trial_shipments: "currency",
    debt_payment: "currency",
    general_costs: "currency",
    vat: "currency",
    charge_back: "currency",
    amazon_service_fees: "currency",
    refund_cost: "currency",
    removal_shipment: "currency",
    removal_shipment_adjustment: "currency",
    affordability_expense: "currency",
    guarantee_claim: "currency",
    retro_charge: "currency",
  };
  const list = [
    {
      type: "sales",
      "2024-10": "21132.65",
      "2024-09": "16415.02",
      "2024-08": "9904.96",
      "2024-07": "6373.41",
      "2024-06": "5205.67",
      "2024-05": "8541.9",
      "2024-04": "9961.33",
      "2024-03": "10760.39",
      "2024-02": "9396.48",
      "2024-01": "6555.53",
      "2023-12": "2189.5",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "organic",
          "2024-10": "9807.96",
          "2024-09": "6290.46",
          "2024-08": "3642.43",
          "2024-07": "2509.87",
          "2024-06": "2434.4",
          "2024-05": "3901.09",
          "2024-04": "4353.64",
          "2024-03": "4103.64",
          "2024-02": "2970.93",
          "2024-01": "1145.94",
          "2023-12": "2189.5",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_products",
          "2024-10": "10890.83",
          "2024-09": "9513.85",
          "2024-08": "6262.53",
          "2024-07": "3755.57",
          "2024-06": "2771.27",
          "2024-05": "4640.81",
          "2024-04": "5511.72",
          "2024-03": "6501.8",
          "2024-02": "6425.55",
          "2024-01": "5329.69",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_brands",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_brands_video",
          "2024-10": "433.86",
          "2024-09": "610.71",
          "2024-08": "0",
          "2024-07": "63.98",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "95.97",
          "2024-03": "154.95",
          "2024-02": "0",
          "2024-01": "79.9",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_display",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "43.99",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "merchant_shipping",
          "2024-10": null,
          "2024-09": null,
          "2024-08": null,
          "2024-07": null,
          "2024-06": null,
          "2024-05": null,
          "2024-04": null,
          "2024-03": null,
          "2024-02": null,
          "2024-01": null,
          "2023-12": null,
          "2023-11": null,
          "2023-10": null,
          "2023-09": null,
        },
      ],
    },
    {
      type: "units_sold",
      "2024-10": "823",
      "2024-09": "691",
      "2024-08": "405",
      "2024-07": "315",
      "2024-06": "219",
      "2024-05": "285",
      "2024-04": "314",
      "2024-03": "366",
      "2024-02": "256",
      "2024-01": "236",
      "2023-12": "72",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "organic_unit",
          "2024-10": "421",
          "2024-09": "356",
          "2024-08": "187",
          "2024-07": "176",
          "2024-06": "115",
          "2024-05": "146",
          "2024-04": "149",
          "2024-03": "147",
          "2024-02": "88",
          "2024-01": "101",
          "2023-12": "72",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_products_unit",
          "2024-10": "388",
          "2024-09": "312",
          "2024-08": "218",
          "2024-07": "136",
          "2024-06": "104",
          "2024-05": "139",
          "2024-04": "162",
          "2024-03": "214",
          "2024-02": "168",
          "2024-01": "133",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_brands_unit",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_brands_video_unit",
          "2024-10": "14",
          "2024-09": "23",
          "2024-08": "0",
          "2024-07": "2",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "3",
          "2024-03": "5",
          "2024-02": "0",
          "2024-01": "2",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_display_unit",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "1",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
      ],
    },
    {
      type: "refunds",
      "2024-10": "72",
      "2024-09": "68",
      "2024-08": "26",
      "2024-07": "33",
      "2024-06": "16",
      "2024-05": "29",
      "2024-04": "18",
      "2024-03": "28",
      "2024-02": "26",
      "2024-01": "7",
      "2023-12": "0",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [],
    },
    {
      type: "tax",
      "2024-10": "0",
      "2024-09": "0",
      "2024-08": "0",
      "2024-07": "-15.35",
      "2024-06": "0",
      "2024-05": "0",
      "2024-04": "0",
      "2024-03": "0",
      "2024-02": "0",
      "2024-01": "0",
      "2023-12": "0",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "order_tax",
          "2024-10": "1481.86",
          "2024-09": "1127.29",
          "2024-08": "666.39",
          "2024-07": "382.14",
          "2024-06": "330.97",
          "2024-05": "591.4",
          "2024-04": "698.16",
          "2024-03": "793.69",
          "2024-02": "666.24",
          "2024-01": "387.51",
          "2023-12": "165.37",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "marketplace_facilitator_tax_principal",
          "2024-10": -1481.86,
          "2024-09": -1127.29,
          "2024-08": -666.39,
          "2024-07": -397.49,
          "2024-06": -330.97,
          "2024-05": -591.4,
          "2024-04": -698.16,
          "2024-03": -787.93,
          "2024-02": -645.54,
          "2024-01": -387.51,
          "2023-12": -165.37,
          "2023-11": 0,
          "2023-10": 0,
          "2023-09": 0,
        },
        {
          type: "shipping_tax",
          "2024-10": "1.98",
          "2024-09": "2.09",
          "2024-08": "1.27",
          "2024-07": "0",
          "2024-06": "1.05",
          "2024-05": "2.57",
          "2024-04": "1.72",
          "2024-03": "8.57",
          "2024-02": "9.15",
          "2024-01": "0.57",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "marketplace_facilitator_tax_shipping",
          "2024-10": "-1.98",
          "2024-09": "-2.09",
          "2024-08": "-1.27",
          "2024-07": "0",
          "2024-06": "-1.05",
          "2024-05": "-2.57",
          "2024-04": "-1.72",
          "2024-03": "-5.81",
          "2024-02": "-1.05",
          "2024-01": "-0.57",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "gift_wrap_tax",
          "2024-10": "0",
          "2024-09": "0.4",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "marketplace_facilitator_tax_gift_wrap",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "marketplace_facilitator_tax_other",
          "2024-10": "0",
          "2024-09": "-0.4",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "marketplace_facilitator_tax_restocking_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "marketplace_facilitator_vat_principal",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "-5.76",
          "2024-02": "-20.7",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "marketplace_facilitator_vat_shipping",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "-2.76",
          "2024-02": "-8.1",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "marketplace_facilitator_vat_restocking_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "tcs_cgst",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "tcs_sgst",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "tcs_igst",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "tcs_utgst",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "cod_item_tax_charge",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "cod_order_tax_charge",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "cod_shipping_tax_charge",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "tax_discount",
          "2024-10": 0,
          "2024-09": 0,
          "2024-08": 0,
          "2024-07": 0,
          "2024-06": 0,
          "2024-05": 0,
          "2024-04": 0,
          "2024-03": 0,
          "2024-02": 0,
          "2024-01": 0,
          "2023-12": 0,
          "2023-11": 0,
          "2023-10": 0,
          "2023-09": 0,
        },
        {
          type: "low_value_goods_tax_principal",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "low_value_goods_tax_shipping",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "low_value_goods_tax_other",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
      ],
    },
    {
      type: "additional_charges",
      "2024-10": "223.49",
      "2024-09": "269.33",
      "2024-08": "60.62",
      "2024-07": "85.51",
      "2024-06": "89.07",
      "2024-05": "227.67",
      "2024-04": "178.29",
      "2024-03": "276.84",
      "2024-02": "205.49",
      "2024-01": "78.38",
      "2023-12": "51.45",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "gift_wrap",
          "2024-10": "0",
          "2024-09": "4.99",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "shipping_charge",
          "2024-10": "223.49",
          "2024-09": "264.34",
          "2024-08": "60.62",
          "2024-07": "85.51",
          "2024-06": "89.07",
          "2024-05": "227.67",
          "2024-04": "178.29",
          "2024-03": "276.84",
          "2024-02": "205.49",
          "2024-01": "78.38",
          "2023-12": "51.45",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "good_will",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "restocking_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "return_shipping",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "points_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "generic_deduction",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "free_replacement_return_shipping",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "payment_method_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "export_charge",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "cod_charge_for_order_item",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "cod_charge_for_order",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "cod_charge_for_shipping",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "cod_revenue",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "low_value_goods_principal",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "low_value_goods_shipping",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "low_value_goods_other",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
      ],
    },
    {
      type: "fba_fees",
      "2024-10": "-9044.12",
      "2024-09": "-7599.09",
      "2024-08": "-4837.57",
      "2024-07": "-4026.3",
      "2024-06": "-2592.67",
      "2024-05": "-3773.76",
      "2024-04": "-4208.91",
      "2024-03": "-5146.24",
      "2024-02": "-4006.52",
      "2024-01": "-3571.67",
      "2023-12": "-1208.64",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "fba_per_order_fulfillment_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "fba_per_unit_fulfillment_fee",
          "2024-10": -6495.06,
          "2024-09": -5299.27,
          "2024-08": -3519.56,
          "2024-07": -3360.04,
          "2024-06": -2249.05,
          "2024-05": -2905.12,
          "2024-04": -3242.84,
          "2024-03": -3882.58,
          "2024-02": -2778.65,
          "2024-01": -2766.67,
          "2023-12": -874.74,
          "2023-11": 0,
          "2023-10": 0,
          "2023-09": 0,
        },
        {
          type: "fba_weight_based_fee",
          "2024-10": "0",
          "2024-09": 0,
          "2024-08": 0,
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "commission",
          "2024-10": -2476.08,
          "2024-09": -1969.68,
          "2024-08": -1094.32,
          "2024-07": -572.06,
          "2024-06": -326.64,
          "2024-05": -786.54,
          "2024-04": -943.44,
          "2024-03": -1154.55,
          "2024-02": -1080.29,
          "2024-01": -731.42,
          "2023-12": -317.81,
          "2023-11": 0,
          "2023-10": 0,
          "2023-09": 0,
        },
        {
          type: "fixed_closing_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "gift_wrap_charge_back",
          "2024-10": "0",
          "2024-09": "-4.99",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "variable_closing_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "amazon_exclusives_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sales_tax_collection_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "shipping_charge_back",
          "2024-10": "-72.98",
          "2024-09": "-92.89",
          "2024-08": "-20.19",
          "2024-07": "0",
          "2024-06": "-13.98",
          "2024-05": "-70.1",
          "2024-04": "-22.63",
          "2024-03": "-109.11",
          "2024-02": "-83.38",
          "2024-01": "-8.78",
          "2023-12": "-12.49",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "imaging_services_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "review_enrollment_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "regulatory_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "coupon_redemption_fee",
          "2024-10": 0,
          "2024-09": -176.4,
          "2024-08": -93,
          "2024-07": -94.2,
          "2024-06": -3,
          "2024-05": -12,
          "2024-04": 0,
          "2024-03": 0,
          "2024-02": -64.2,
          "2024-01": -64.8,
          "2023-12": -3.6,
          "2023-11": 0,
          "2023-10": 0,
          "2023-09": 0,
        },
        {
          type: "run_lightning_deal_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "enrollment_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
      ],
    },
    {
      type: "amazon_service_fees",
      "2024-10": "-2676.94",
      "2024-09": "-2016.46",
      "2024-08": "-980.16",
      "2024-07": "-744.29",
      "2024-06": "-168.64",
      "2024-05": "-161.07",
      "2024-04": "-337.33",
      "2024-03": "-402.63",
      "2024-02": "-233.06",
      "2024-01": "-727.11",
      "2023-12": "-25.54",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "subscription",
          "2024-10": -25.97,
          "2024-09": -25.7,
          "2024-08": -25.71,
          "2024-07": -39.99,
          "2024-06": -25.82,
          "2024-05": -39.99,
          "2024-04": -39.99,
          "2024-03": -39.99,
          "2024-02": -39.99,
          "2024-01": -39.99,
          "2023-12": -25.54,
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "fba_storage_fee",
          "2024-10": -462.99,
          "2024-09": -162.3,
          "2024-08": -81.84,
          "2024-07": -49.13,
          "2024-06": -72.61,
          "2024-05": -117.48,
          "2024-04": -166.82,
          "2024-03": -131.72,
          "2024-02": -52.51,
          "2024-01": -87.12,
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "vine_fee",
          "2024-10": -1400,
          "2024-09": -1200,
          "2024-08": -600,
          "2024-07": -600,
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": -600,
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "fba_disposal_fee",
          "2024-10": -3.95,
          "2024-09": -10.04,
          "2024-08": "0",
          "2024-07": -20.08,
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": -130.52,
          "2024-03": -230.92,
          "2024-02": -140.56,
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "re_commerce_grading_and_listing_charge",
          "2024-10": null,
          "2024-09": null,
          "2024-08": null,
          "2024-07": null,
          "2024-06": -3.6,
          "2024-05": -3.6,
          "2024-04": null,
          "2024-03": null,
          "2024-02": null,
          "2024-01": null,
          "2023-12": null,
          "2023-11": null,
          "2023-10": null,
          "2023-09": null,
        },
        {
          type: "amazon_upstream_processing_fee",
          "2024-10": -142.37,
          "2024-09": -133.86,
          "2024-08": -108.37,
          "2024-07": -12.75,
          "2024-06": -35,
          "2024-05": null,
          "2024-04": null,
          "2024-03": null,
          "2024-02": null,
          "2024-01": null,
          "2023-12": null,
          "2023-11": null,
          "2023-10": null,
          "2023-09": null,
        },
        {
          type: "amazon_upstream_storage_transportation_fee",
          "2024-10": -170.64,
          "2024-09": -148.47,
          "2024-08": -117.06,
          "2024-07": -11.94,
          "2024-06": -31.61,
          "2024-05": null,
          "2024-04": null,
          "2024-03": null,
          "2024-02": null,
          "2024-01": null,
          "2023-12": null,
          "2023-11": null,
          "2023-10": null,
          "2023-09": null,
        },
        {
          type: "s_t_a_r_storage_fee",
          "2024-10": null,
          "2024-09": null,
          "2024-08": null,
          "2024-07": -10.4,
          "2024-06": null,
          "2024-05": null,
          "2024-04": null,
          "2024-03": null,
          "2024-02": null,
          "2024-01": null,
          "2023-12": null,
          "2023-11": null,
          "2023-10": null,
          "2023-09": null,
        },
        {
          type: "fba_inbound_convenience_fee",
          "2024-10": -389.68,
          "2024-09": -349.86,
          "2024-08": -146,
          "2024-07": null,
          "2024-06": null,
          "2024-05": null,
          "2024-04": null,
          "2024-03": null,
          "2024-02": null,
          "2024-01": null,
          "2023-12": null,
          "2023-11": null,
          "2023-10": null,
          "2023-09": null,
        },
        {
          type: "fba_per_unit_fulfillment_fee",
          "2024-10": -6495.06,
          "2024-09": -5299.27,
          "2024-08": -3519.56,
          "2024-07": -3360.04,
          "2024-06": -2249.05,
          "2024-05": -2905.12,
          "2024-04": -3242.84,
          "2024-03": -3882.58,
          "2024-02": -2778.65,
          "2024-01": -2766.67,
          "2023-12": -874.74,
          "2023-11": 0,
          "2023-10": 0,
          "2023-09": 0,
        },
        {
          type: "fba_weight_based_fee",
          "2024-10": "0",
          "2024-09": 0,
          "2024-08": 0,
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "s_t_a_r_storage_billing",
          "2024-10": -81.34,
          "2024-09": -42.09,
          "2024-08": -11.68,
          "2024-07": null,
          "2024-06": null,
          "2024-05": null,
          "2024-04": null,
          "2024-03": null,
          "2024-02": null,
          "2024-01": null,
          "2023-12": null,
          "2023-11": null,
          "2023-10": null,
          "2023-09": null,
        },
      ],
    },
    {
      type: "refund_cost",
      "2024-10": "-1490.43",
      "2024-09": "-1350.96",
      "2024-08": "-452.27",
      "2024-07": "-403.74",
      "2024-06": "-257.58",
      "2024-05": "-604.46",
      "2024-04": "-359.87",
      "2024-03": "-571.82",
      "2024-02": "-575.97",
      "2024-01": "-118.8",
      "2023-12": "0",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "order_returns_revenue",
          "2024-10": "-1911.05",
          "2024-09": "-1685.29",
          "2024-08": "-619.74",
          "2024-07": "-774.64",
          "2024-06": "-425.76",
          "2024-05": "-896.31",
          "2024-04": "-527.83",
          "2024-03": "-877.45",
          "2024-02": "-932.91",
          "2024-01": "-269.63",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_tax",
          "2024-10": "-145.65",
          "2024-09": "-110.05",
          "2024-08": "-39.51",
          "2024-07": "-47.83",
          "2024-06": "-29.5",
          "2024-05": "-64.54",
          "2024-04": "-35.68",
          "2024-03": "-65.62",
          "2024-02": "-64.64",
          "2024-01": "-15.81",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refunded_fba_fees",
          "2024-10": "233.78",
          "2024-09": "176.65",
          "2024-08": "63.83",
          "2024-07": "63.93",
          "2024-06": "42.6",
          "2024-05": "77.26",
          "2024-04": "37.95",
          "2024-03": "103.03",
          "2024-02": "76.96",
          "2024-01": "21.64",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refunded_commission_fees",
          "2024-10": "-46.75",
          "2024-09": "-35.34",
          "2024-08": "-12.76",
          "2024-07": "-12.79",
          "2024-06": "-8.52",
          "2024-05": "-15.45",
          "2024-04": "-7.59",
          "2024-03": "-20.6",
          "2024-02": "-15.42",
          "2024-01": "-4.33",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_shipping_chargeback",
          "2024-10": "0.47",
          "2024-09": "6.99",
          "2024-08": "4.21",
          "2024-07": "0",
          "2024-06": "6.99",
          "2024-05": "0",
          "2024-04": "1.18",
          "2024-03": "9.48",
          "2024-02": "1.25",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_promotions",
          "2024-10": "27.17",
          "2024-09": "72.51",
          "2024-08": "41.1",
          "2024-07": "152.46",
          "2024-06": "9.9",
          "2024-05": "14.94",
          "2024-04": "0",
          "2024-03": "22.98",
          "2024-02": "91.74",
          "2024-01": "75.22",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "cod_refund",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_shipping_charge",
          "2024-10": "-10.45",
          "2024-09": "-9.98",
          "2024-08": "-4.21",
          "2024-07": "0",
          "2024-06": "-6.99",
          "2024-05": "0",
          "2024-04": "-1.18",
          "2024-03": "-15.46",
          "2024-02": "-10.99",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_shipping_tax",
          "2024-10": "-0.03",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "-0.57",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_marketplace_facilitator_tax_principal",
          "2024-10": "145.65",
          "2024-09": "110.05",
          "2024-08": "39.51",
          "2024-07": "47.83",
          "2024-06": "29.88",
          "2024-05": "64.54",
          "2024-04": "35.68",
          "2024-03": "65.62",
          "2024-02": "64.64",
          "2024-01": "15.81",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_marketplace_facilitator_tax_shipping",
          "2024-10": "0.03",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0.57",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_restocking_fee",
          "2024-10": "0",
          "2024-09": "3",
          "2024-08": "3.4",
          "2024-07": "0",
          "2024-06": "11.8",
          "2024-05": "0",
          "2024-04": "5.4",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_marketplace_facilitator_tax_other",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_marketplace_facilitator_tax_restocking_fee",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "-0.38",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_marketplace_facilitator_vat_principal",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_marketplace_facilitator_vat_shipping",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "refund_item_cog",
          "2024-10": "216.4",
          "2024-09": "120.5",
          "2024-08": "71.9",
          "2024-07": "167.3",
          "2024-06": "112.4",
          "2024-05": "215.1",
          "2024-04": "132.2",
          "2024-03": "206.2",
          "2024-02": "213.4",
          "2024-01": "58.3",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
      ],
    },
    {
      type: "removal_shipment",
      "2024-10": 19.96,
      "2024-09": 18.78,
      "2024-08": 19.65,
      "2024-07": "0",
      "2024-06": "0",
      "2024-05": "0",
      "2024-04": "0",
      "2024-03": "0",
      "2024-02": "0",
      "2024-01": "0",
      "2023-12": "0",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "removal_fee_amount",
          "2024-10": -16.18,
          "2024-09": -15.23,
          "2024-08": -1.51,
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "removal_revenue",
          "2024-10": 36.14,
          "2024-09": 34.01,
          "2024-08": 21.16,
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "removal_tax",
          "2024-10": 0,
          "2024-09": 0,
          "2024-08": 0,
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "removal_tax_withheld",
          "2024-10": 0,
          "2024-09": 0,
          "2024-08": 0,
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
      ],
    },
    {
      type: "PPC_costs",
      "2024-10": "-5600.34",
      "2024-09": "-6209.01",
      "2024-08": "-4048.08",
      "2024-07": "-2541.89",
      "2024-06": "-1623.24",
      "2024-05": "-3050.22",
      "2024-04": "-3381.41",
      "2024-03": "-3337.07",
      "2024-02": "-2920.43",
      "2024-01": "-2138.5",
      "2023-12": "-7.32",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "sponsored_products_ppc",
          "2024-10": "-5341.01",
          "2024-09": "-5406.37",
          "2024-08": "-4042.25",
          "2024-07": "-2397.96",
          "2024-06": "-1623.24",
          "2024-05": "-3050.22",
          "2024-04": "-3262.09",
          "2024-03": "-3119.46",
          "2024-02": "-2920.43",
          "2024-01": "-2058.75",
          "2023-12": "-7.32",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_brands_ppc",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_brands_video_ppc",
          "2024-10": "-259.33",
          "2024-09": "-802.64",
          "2024-08": "0",
          "2024-07": "-94.13",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "-119.32",
          "2024-03": "-217.61",
          "2024-02": "0",
          "2024-01": "-79.75",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "sponsored_display_ppc",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "-5.83",
          "2024-07": "-49.8",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
      ],
    },
    {
      type: "promotions",
      "2024-10": "-257.73",
      "2024-09": "-946.75",
      "2024-08": "-659.08",
      "2024-07": "-1000.3",
      "2024-06": "-542.93",
      "2024-05": "-322.99",
      "2024-04": "-227.81",
      "2024-03": "-167.73",
      "2024-02": "-455.81",
      "2024-01": "-1193.89",
      "2023-12": "-97.15",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [],
    },
    {
      type: "adjustements",
      "2024-10": "31.47",
      "2024-09": "63.95",
      "2024-08": "0",
      "2024-07": "73.2",
      "2024-06": "82.74",
      "2024-05": "81.31",
      "2024-04": "147.05",
      "2024-03": "83.26",
      "2024-02": "0",
      "2024-01": "0",
      "2023-12": "0",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [
        {
          type: "reversal_reimbursement",
          "2024-10": "0",
          "2024-09": 62.25,
          "2024-08": "0",
          "2024-07": 31.46,
          "2024-06": 21.42,
          "2024-05": 81.31,
          "2024-04": 127.38999999999999,
          "2024-03": 51.46,
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "warehouse_lost_manual",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": 45.8,
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": 31.8,
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "warehouse_damage",
          "2024-10": "0",
          "2024-09": 19.63,
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": 23.06,
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "compensated_clawback",
          "2024-10": -23.63,
          "2024-09": -23.42,
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": -16.62,
          "2024-05": "0",
          "2024-04": -15.4,
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "free_replacement_refund_items",
          "2024-10": 22.34,
          "2024-09": 5.49,
          "2024-08": "0",
          "2024-07": 41.74,
          "2024-06": 41.74,
          "2024-05": "0",
          "2024-04": 12,
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "non_subscription_fee_adj",
          "2024-10": "0",
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": -9.6,
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "misc_adjustment",
          "2024-10": 11.68,
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
        {
          type: "missing_from_inbound",
          "2024-10": 21.08,
          "2024-09": "0",
          "2024-08": "0",
          "2024-07": "0",
          "2024-06": "0",
          "2024-05": "0",
          "2024-04": "0",
          "2024-03": "0",
          "2024-02": "0",
          "2024-01": "0",
          "2023-12": "0",
          "2023-11": "0",
          "2023-10": "0",
          "2023-09": "0",
        },
      ],
    },
    {
      type: "adhoc_disbursement",
      "2024-10": 0,
      "2024-09": 0,
      "2024-08": 0,
      "2024-07": 0,
      "2024-06": 0,
      "2024-05": 0,
      "2024-04": 0,
      "2024-03": 0,
      "2024-02": 0,
      "2024-01": -0.01,
      "2023-12": 0,
      "2023-11": 0,
      "2023-10": 0,
      "2023-09": 0,
      child: [
        {
          type: "micro_deposit",
          "2024-10": 0,
          "2024-09": 0,
          "2024-08": 0,
          "2024-07": 0,
          "2024-06": 0,
          "2024-05": 0,
          "2024-04": 0,
          "2024-03": 0,
          "2024-02": 0,
          "2024-01": -0.01,
          "2023-12": 0,
          "2023-11": 0,
          "2023-10": 0,
          "2023-09": 0,
        },
      ],
    },
    {
      type: "debt_payment",
      "2024-10": 0,
      "2024-09": -1453.78,
      "2024-08": -2328.13,
      "2024-07": -1465.69,
      "2024-06": 0,
      "2024-05": 0,
      "2024-04": 0,
      "2024-03": 0,
      "2024-02": 0,
      "2024-01": -548.8,
      "2023-12": 0,
      "2023-11": 0,
      "2023-10": 0,
      "2023-09": 0,
      child: [],
    },
    {
      type: "total_cogs",
      "2024-10": "-3015.9",
      "2024-09": "-1662.2",
      "2024-08": "-1079.7",
      "2024-07": "-1383.5",
      "2024-06": "-1337.2",
      "2024-05": "-2058.6",
      "2024-04": "-2382.6",
      "2024-03": "-2724.4",
      "2024-02": "-2103.4",
      "2024-01": "-1968.4",
      "2023-12": "-570.8",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [],
    },
    {
      type: "total_profit",
      "2024-10": "-677.89",
      "2024-09": "-4471.17",
      "2024-08": "-4399.76",
      "2024-07": "-5048.94",
      "2024-06": "-1144.78",
      "2024-05": "-1120.22",
      "2024-04": "-611.26",
      "2024-03": "-1229.4",
      "2024-02": "-693.22",
      "2024-01": "-3633.27",
      "2023-12": "331.5",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [],
    },
    {
      type: "profit_margin",
      "2024-10": "-3.21",
      "2024-09": "-27.24",
      "2024-08": "-44.42",
      "2024-07": "-79.22",
      "2024-06": "-21.99",
      "2024-05": "-13.11",
      "2024-04": "-6.14",
      "2024-03": "-11.43",
      "2024-02": "-7.38",
      "2024-01": "-55.42",
      "2023-12": "15.14",
      "2023-11": "0",
      "2023-10": "0",
      "2023-09": "0",
      child: [],
    },
  ];

  const numberformat = (value, type) => {
    if (value === "-") return value;
    return parseFloat(value || 0).toLocaleString("en-US", {
      minimumFractionDigits: type === 0 ? 0 : type || 2,
    });
  };
  const sign = "$";
  const colorChangeText = (value, row) => {
    const sign_ = row?.sign;

    if (value == 0) {
      return (
        <span
          style={{
            color: "#414141",
            fontWeight: 400,
            
            fontSize: "16px",
          }}
        >
          {(
            (sign_ === "currency" ? sign : "") +
            0.0 +
            (sign_ === "pr" ? "%" : "")
          ).replace(`${sign}-`, `-${sign}`)}
        </span>
      );
    }
    if (value > 0) {
      return (
        <span
          style={{
            color: "#00b008",
            fontWeight: 400,
            
            fontSize: "15px",
          }}
        >
          {(
            (sign_ === "currency" ? sign : "") +
            numberformat(
              parseFloat(value || 0).toFixed(2),
              sign_ === "" ? 0 : 2
            ) +
            (sign_ === "pr" ? "%" : "")
          ).replace(`${sign}-`, `-${sign}`)}
        </span>
      );
    }
    return (
      <span
        style={{
          color: "#F03A3A",
          fontWeight: 400,
          
          fontSize: "15px",
        }}
      >
        {(
          (sign_ === "currency" ? sign : "") +
          numberformat(
            parseFloat(value || 0).toFixed(2),
            sign_ === "" ? 0 : 2
          ) +
          (sign_ === "pr" ? "%" : "")
        ).replace(`${sign}-`, `-${sign}`)}
      </span>
    );
  };

  const columnsList = Object?.keys(list?.[0] || {})?.filter(
    (d) => d !== "child" && d !== "type"
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRows([...expandedRows, record.key]);
    } else {
      setExpandedRows(expandedRows.filter((key) => key !== record.key));
    }
  };
  const expandIcon = ({ expanded, onExpand, record }) =>
    record?.child?.length === undefined || record?.child?.length === 0 ? (
      <></>
    ) : (
      <span onClick={(e) => onExpand(record, e)}>
        {!expanded ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={6}
            height={10}
            viewBox="0 0 6 10"
            fill="none"
            style={{ cursor: "pointer" }}
          >
            <path
              d="M3.00053 5L1.98842e-08 1.66745L1.49973 5.36651e-08L6 5L1.49973 10L9.93646e-08 8.33255L3.00053 5Z"
              fill="#333"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={10}
            height={6}
            viewBox="0 0 10 6"
            style={{ cursor: "pointer" }}
            fill="none"
          >
            <path
              d="M5 3.00053L8.33255 -1.45773e-07L10 1.49973L5 6L3.93426e-07 1.49973L1.66745 -7.28454e-07L5 3.00053Z"
              fill="#333"
            />
          </svg>
        )}
      </span>
    );
  const columnsListSub = (record) => {
    return Object?.keys(record?.child?.[0] || {})?.filter(
      (d) => d !== "child" && d !== "type"
    );
  };
  const expandedRowRender = (record) => {
    const filteredItems = (record?.child || [])
      .map((item, index) => {
        const { type, ...rest } = item;

        if (
          Object.values(rest).some(
            (value) => parseFloat(value) !== 0 && value !== null
          )
        ) {
          return { ...item, key: index.toString() };
        }

        return null;
      })
      .filter(Boolean);
    return (
      <Table
        columns={[
          Table.EXPAND_COLUMN,
          {
            title: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#333",
                  fontWeight: 800,
                  fontSize: "15px",
                }}
              >
                Type
              </span>
            ),

            width: window.innerWidth <= 992 ? 200 : 335,
            dataIndex: "type",

            key: "type",
            render: (e, row) => {
              return (
                <>
                  <Tooltip rule>
                    <div
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 900,
                        fontSize: "15px",

                        width: `${window.innerWidth <= 992 ? 200 : 300}px`,
                      }}
                    >
                      {e?.split("_")?.join(" ")}
                    </div>
                  </Tooltip>
                </>
              );
            },
          },
          ...columnsListSub(record)?.map((d) => ({
            title: (
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#333",
                  fontWeight: 800,
                  fontSize: "15px",
                }}
              >
                {dayjs(new Date(d)).format("MMM YYYY")}
                {/* {getType === "last_12_months_by_month"
                  ? dayjs(new Date(d)).format("MMM YYYY")
                  : getType === "last_3_month_by_week"
                  ? d
                  : getType === "last_30_days_by_day"
                  ? dayjs(new Date(d)).format("MMM DD, YYYY")
                  : dayjs(new Date(d)).format("MMM YYYY")} */}
              </span>
            ),
            align: "right",
            dataIndex: d,
            key: d,
            width: 160,
            render: (e) => {
              return (
                <b>
                  {`${record?.sign === "currency" ? sign : ""}
                  ${numberformat(
                    parseFloat(e || 0).toFixed(
                      record?.sign === "currency" ||
                        record?.sign === "pr" ||
                        record?.type === "retro_charge"
                        ? 2
                        : 0
                    ),
                    record?.sign === "currency" || record?.sign === "pr" ? 2 : 0
                  )}
                  ${record?.sign === "pr" ? "%" : ""}`
                    .replace(/\s+/g, "")
                    ?.replace(`${sign}-`, `-${sign}`)}
                </b>
              );
            },
          })),
        ]}
        dataSource={filteredItems}
        showHeader={false}
        scroll={{ x: "max-content" }}
        pagination={false}
        expandable={{
          expandedRowRender: () => {},
          expandIcon,
          onExpand: handleExpand,
        }}
      />
    );
  };

  return (
    <Wrapper
      className="container-fluid fadeInLeftAllPage"
      id="kt_content_container"
    >
      <div className="row gy-5 g-xl-5 mb-5 px-0 mx-0">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title"></div>
              <div className="card-toolbar">
                <DatePicker.RangePicker size="large" variant='filled' className='w-250px' />
                <Button size='large' className='fs-7 fw-bold ms-2' type='primary'>
                  Export
                </Button>
              </div>
            </div>
            <div className="card-body">
              <Table
                columns={[
                  Table.EXPAND_COLUMN,
                  {
                    title: (
                      <span
                        style={{
                          textTransform: "capitalize",
                          color: "#333",
                          fontWeight: 800,
                          fontSize: "15.6px",
                        }}
                      >
                        Type
                      </span>
                    ),
                    width: window.innerWidth <= 992 ? 200 : 300,
                    dataIndex: "type",
                    key: "type",
                    render: (e, row) => {
                      return (
                        <Tooltip rule>
                          <div
                            style={{
                              textTransform: "capitalize",
                              fontWeight: 900,
                              fontSize: "15.6px",
                              width: `${
                                window.innerWidth <= 992 ? 200 : 300
                              }px`,
                              // 

                              color: "#333",
                            }}
                          >
                            {e?.split("_")?.join(" ")}
                          </div>
                        </Tooltip>
                      );
                    },
                  },
                  ...columnsList?.map((d) => ({
                    title: (
                      <span
                        style={{
                          textTransform: "capitalize",
                          color: "#333",
                          fontWeight: 800,
                          fontSize: "15.6px",
                        }}
                      >
                        {dayjs(new Date(d)).format("MMM YYYY")}
                        {/* {typeSelected === "last_12_months_by_month"
                        ? dayjs(new Date(d)).format("MMM YYYY")
                        : typeSelected === "last_30_days_by_day"
                        ? dayjs(new Date(d)).format("MMM DD, YYYY")
                        : d} */}
                      </span>
                    ),
                    dataIndex: d,
                    key: d,
                    width: 160,
                    align: "right",
                    render: (e, row) => {
                      return (
                        <span style={{ fontWeight: 800, color: "#00000098" }}>
                          {colorChangeText(e, row)}
                        </span>
                      );
                    },
                  })),
                ]}
                dataSource={(list || []).map((d, i) => {
                  const { child, type, ...rest } = d;
                  const filteredItems = (child || [])
                    .map((item, index) => {
                      const { type, ...rest } = item;

                      if (
                        Object.values(rest).some(
                          (value) => parseFloat(value) !== 0 && value !== null
                        )
                      ) {
                        return { ...item, type: type, key: index.toString() };
                      }

                      return null;
                    })
                    .filter(Boolean);

                  if (
                    Object.values(rest).every(
                      (value) => parseFloat(value) === 0
                    ) &&
                    filteredItems?.length === 0
                  )
                    return {};
                  return { ...d, key: i, sign: obj?.[d?.type] };
                })}
                pagination={false}
                scroll={{ x: "max-content" }}
                expandable={{
                  expandedRowRender,
                  expandIcon,
                  onExpand: handleExpand,
                  expandedRowKeys: expandedRows,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PLStatement;
