import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";

const EmailFormModal = ({ visible, onClose, onSubmit, initialValues }) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  // Set initial form values if editing
  if (initialValues) {
    form.setFieldsValue(initialValues);
  }

  // Handle form submission
  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (initialValues) {
        setSubmitLoading(true);
        onSubmit(values, "edit");
      } else {
        setSubmitLoading(true);
        onSubmit(values, "add");
      }
    } catch (error) {}
  };

  const onCloseModal = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      width={700}
      title={initialValues ? "Edit Email Data" : "Add Email Data"}
      onCancel={() => {
        onCloseModal();
      }}
      footer={
        initialValues?.type === "view"
          ? []
          : [
              <Button key="cancel" onClick={() => onCloseModal()}>
                Cancel
              </Button>,
              <Button
                loading={submitLoading}
                key="submit"
                type="primary"
                onClick={handleFormSubmit}
              >
                {initialValues ? "Update" : "Add"}
              </Button>,
            ]
      }
    >
      <Form
        disabled={initialValues?.type === "view"}
        form={form}
        layout="vertical"
        initialValues={initialValues || {}}
      >
        <Form.Item
          label="Sender Email Address"
          name="sender_email_address"
          rules={[
            { required: true, message: "Sender Email Address is required." },
            { type: "email", message: "Enter a valid email address." },
          ]}
        >
          <Input placeholder="Enter sender's email" />
        </Form.Item>

        <Form.Item
          label="Slug"
          name="slug"
          rules={[{ required: true, message: "Slug is required." }]}
        >
          <Input placeholder="Enter slug" />
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          rules={[{ required: true, message: "Subject is required." }]}
        >
          <Input placeholder="Enter email subject" />
        </Form.Item>

        <Form.Item
          label="Email Body"
          name="content"
          rules={[{ required: true, message: "Email Body is required." }]}
        >
          <Input.TextArea placeholder="Enter email body" rows={4} />
        </Form.Item>

        <Form.Item
          label="Replacement Tags"
          name="tags"
          rules={[
            { required: true, message: "Replacement Tags are required." },
          ]}
        >
          <Input placeholder="Enter replacement tags" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default EmailFormModal;
