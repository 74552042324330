import styled from "styled-components";
import { Card } from "antd";
import {
  primaryColor,
  secondaryColor,
  thirdColor,
  trackBg,
} from "../../../../config";

export const Wrapper = styled.div`
  padding: 20px;

  .head {
    margin-bottom: 20px;
    color: ${secondaryColor};
    font-size: 24px;
    font-weight: bold;
  }

  /* Enhanced Segmented control styling */
  .ant-segmented {
    padding: 4px 10px;
    border-radius: 25px;
    background-color: #f0f7fc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .ant-segmented-group {
      gap: 10px;
      flex-wrap: wrap;
    }
    .ant-segmented-item-label {
      font-size: 14px !important;
    }
    .ant-segmented-item {
      font-size: 16px;
      font-weight: 500;
      color: ${secondaryColor};
      padding: 4px 5px;
      border-radius: 25px;
      transition: all 0.3s ease;

      //   &:hover {
      //     background-color: ${thirdColor};
      //     color: #fff;
      //   }
    }

    .ant-segmented-item-selected {
      background-color: ${primaryColor};
      color: #fff;
      font-weight: bold;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      transform: scale(1.05);
      transition: all 0.3s ease;
    }
  }
`;

// Styled Card Component for background colors and other enhancements
export const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    125deg,
    rgba(255, 255, 255, 0.8) 15%,
    ${(props) => props.bgColor} 85%
  );
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='300' height='300' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.3'%3E%3Ccircle cx='10' cy='10' r='4'/%3E%3Ccircle cx='30' cy='10' r='4'/%3E%3Ccircle cx='10' cy='30' r='4'/%3E%3Ccircle cx='30' cy='30' r='4'/%3E%3C/g%3E%3C/svg%3E");
  }

  .ant-card-body {
    padding: 14px 15px 8px 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    z-index: 1;
  }

  .icon-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
  }

  .card-icon {
    font-size: 36px;
    // color: ${secondaryColor};
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }

  .card-price {
    font-size: 24px;
    font-weight: bold;
    color: #2c3e50;
    margin: 5px 0 9px 0;
  }

  .card-info {
    font-size: 12px;
    color: #555;

    span {
      display: block;
    }
  }
`;
