import styled from "styled-components";

export const HeaderWrapper = styled.div`
  // .stickeyheader {
  //   position: sticky !important;
  //   border: 1px solid red !important;
  //   top: 0px !important;
  //   background: #f9f9f9 !important;
  //   z-index: 99;
  // }
  .title-text {
    text-shadow: 2px 2px 4px rgb(59 126 197), -2px -2px 20px rgb(255 50 13 / 62%);
  }
         .slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes slideInDown {
  0% {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  visibility: visible;
  }
  100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  }
  }
  @keyframes slideInDown {
  0% {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  visibility: visible;
  }
  100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  }
  } 
×
`;
