import { Input, message, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import {
  ConvertParams,
  DisplayNotification,
  PaginationConfig,
} from "../../../../config";
import { MakeApiCall } from "../../../../apis/make-api-call";
import Table2 from "../../../../component/antd-table/table2";
import moment from "moment";
import AddManager from "./lib/add-manager";

const ReimbursementManager = () => {
  const [sortFilters, setSortFilters] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tableData, setTableData] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const [isOpenManagerModal, setIsOpenManagerModal] = useState(false);
  const [all_filters, set_all_filters] = useState({});
  const [selectedRow, setSelectedRow] = useState({});

  const getTableData = async (data) => {
    try {
      setTableData([]);
      setTableLoading(true);
      const response = await MakeApiCall(
        `manager${ConvertParams(data)}`,
        `get`,
        null,
        true
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setCurrentPage(response?.data?.pagination?.page || 1);
        setPageSize(response?.data?.pagination?.page_size || 1);
        setTableData(response?.data?.records || []);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
        DisplayNotification("error", "Error", response?.message);
      }
    } catch (error) {
      DisplayNotification("error", "Error", error?.message);
      setTableData([]);
      setTableLoading(false);
    }
  };

  const DeleteManager = async (id) => {
    const response = await MakeApiCall(`manager/${id}`, `delete`, null, true);
    if (response?.status) {
      setTableLoading(true);
      setTableData([]);
      setCurrentPage(1);
      getTableData({
        ...all_filters,
        page: 1,
        sort: sortFilters,
        "per-page": pageSize,
      });
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };

  useEffect(() => {
    setTableLoading(true);
    setTableData([]);
    getTableData({
      ...all_filters,
      page: currentPage,
      sort: sortFilters,
      "per-page": pageSize,
    });
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      width: 100,
      ellipsis: true,
      fixed: "left",
      render: (_, __, i) => {
        return (
          <>
            <span>{(currentPage - 1) * pageSize + 1 + i}</span>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return <span className="text-primary fw-bold">{text}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact No",
      dataIndex: "contact_no",
      key: "contact_no",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        return (
          <span>
            {" "}
            {moment(new Date(text * 1000)).format("MMM DD, YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => {
        return (
          <span>
            {" "}
            {moment(new Date(text * 1000)).format("MMM DD, YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text) => {
        return (
          <div>
            <button
              class="btn btn-icon btn-bg-light btn-active-color-success btn-sm"
              onClick={() => {
                setIsOpenManagerModal(true);
                setSelectedRow(text);
              }}
            >
              <i class="ki-outline ki-pencil fs-2 text-success"></i>
            </button>
            <Popconfirm
              title="Delete Manager"
              description="Are you sure to delete this manager?"
              onConfirm={() => {
                DeleteManager(text?.id);
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <span className="btn btn-icon bg-light btn-active-color-primary btn-sm ms-2">
                <i className="ki-outline ki-trash fs-2 text-danger" />
              </span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        <div className="row gy-5 g-xl-5 mb-5 px-0 mx-0">
          <div className="col-xxl-12 px-0 mx-0">
            <div
              className="card"
              style={{ background: " #e9f1fa", border: "1px dashed #3699ff69" }}
            >
              <div className="card-header border-0 d-flex align-items-center p-5">
                <div>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="position-relative">
                      <Input
                        style={{ width: "250px" }}
                        prefix={
                          <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                        }
                        placeholder="Search by name"
                        size="large"
                        value={all_filters?.name}
                        onChange={(e) => {
                          set_all_filters({
                            ...all_filters,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="position-relative "></div>
                    <div className="position-relative ">
                      <button
                        onClick={() => {
                          setPageSize(pageSize);
                          setCurrentPage(1);
                          setTableLoading(true);
                          setTableData([]);
                          getTableData({
                            page: 1,
                            "per-page": pageSize,
                            ...all_filters,
                            sortFilters,
                          });
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        {" "}
                        <i class="ki-outline ki-magnifier fs-2"></i>
                        {/* Search */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row gy-5 g-xl-5 ">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <h3 class="card-title align-items-start flex-column">
                    Manager
                  </h3>
                </div>
                <div className="card-toolbar">
                  {" "}
                  <button
                    className="btn  fs-7 btn-primary fw-bold"
                    onClick={() => {
                      setIsOpenManagerModal(true);
                    }}
                  >
                    Add Manager
                  </button>
                </div>
              </div>
              <div className="separator separator-dashed"></div>
              <div className="card-body py-2 main-box">
                <div className="table-responsive">
                  <Table2
                    columns={columns}
                    setSortFilters={setSortFilters}
                    sortFilters={sortFilters}
                    dataSource={tableData}
                    loading={tableLoading}
                    scroll={{ x: "max-content" }}
                    rowKey="sku"
                    onSortChange={(sort, currentPage, pageSize) => {
                      setPageSize(pageSize);
                      setCurrentPage(currentPage);
                      setTableLoading(true);
                      setTableData([]);
                      getTableData({
                        page: currentPage,
                        "per-page": pageSize,
                        ...all_filters,
                        sort,
                      });
                    }}
                    pagination={PaginationConfig({
                      pageSize,
                      currentPage,
                      totalPage,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenManagerModal && (
        <AddManager
          show={isOpenManagerModal}
          onHide={() => {
            setIsOpenManagerModal(false);
            setSelectedRow({});
          }}
          data={selectedRow}
          onSuccess={() => {
            setIsOpenManagerModal(false);
            setTableLoading(true);
            setTableData([]);
            setCurrentPage(1);
            getTableData({
              ...all_filters,
              page: 1,
              sort: sortFilters,
              "per-page": pageSize,
            });
          }}
        />
      )}
    </>
  );
};

export default ReimbursementManager;
