// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/listing-optimizer",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-graph-3 fs-1"
          // style={{ color: "#32CD32" }}
        ></i>
      </span>
    ),
    label: GetLinks("/listing-optimizer", "Listing Optimizer"),
  },
];
