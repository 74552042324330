import React from 'react'
import { Table, Checkbox, Button, Tag, Space, Card } from "antd";
import { Icon } from "@iconify/react";

function ProductTable({ selectedRowKeys, setSelectedRowKeys, setModalOpen }) {
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          setSelectedRowKeys(selectedRowKeys);
        },
      };
    const data = [
        {
          key: '1',
          product: 'Eden & Co Modern Wall Mounted Coat and Hat Rack – 5 Hooks, Beautiful Wood Entryway Hanger with Pegs - Wooden Bedroom and Bathroom Robe Racks - Durable, Easy Assembly, Classic Design',
          asin: 'B07FCKXQ6P',
          brand: 'Eden & Co',
          img: 'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=',
          categoryName: 'Home & Kitchen',
          lqsScore: 70,
          retailReadiness: 45,
          bqs: 50,
          listingStatus: 'https://static.vecteezy.com/system/resources/previews/019/136/322/non_2x/amazon-logo-amazon-icon-free-free-vector.jpg', // Assuming Listing Status is "Listed" or "Unlisted"
        },
        {
          key: '2',
          product: 'Eden & Co Modern Wall Mounted Coat and Hat Rack – 5 Hooks, Beautiful Wood Entryway Hanger with Pegs - Wooden Bedroom and Bathroom Robe Racks - Durable, Easy Assembly, Classic Design',
          asin: 'B07FCKXQ6P',
          brand: 'Eden & Co',
          img: 'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=',
          categoryName: 'Home & Kitchen',
          lqsScore: 70,
          retailReadiness: 45,
          bqs: 50,
          listingStatus: 'https://static.vecteezy.com/system/resources/previews/019/136/322/non_2x/amazon-logo-amazon-icon-free-free-vector.jpg', // Assuming Listing Status is "Listed" or "Unlisted"
        },
        {
          key: '3',
          product: 'Eden & Co Modern Wall Mounted Coat and Hat Rack – 5 Hooks, Beautiful Wood Entryway Hanger with Pegs - Wooden Bedroom and Bathroom Robe Racks - Durable, Easy Assembly, Classic Design',
          asin: 'B07FCKXQ6P',
          brand: 'Eden & Co',
          img: 'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=',
          categoryName: 'Home & Kitchen',
          lqsScore: 70,
          retailReadiness: 45,
          bqs: 50,
          listingStatus: 'https://static.vecteezy.com/system/resources/previews/019/136/322/non_2x/amazon-logo-amazon-icon-free-free-vector.jpg', // Assuming Listing Status is "Listed" or "Unlisted"
        },
      ];
      
      const columns = [
        {
          title: 'Product',
          className:' w-700px',
          dataIndex: 'product',
          key: 'product',
          render: (text, record) => (
            <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1 min-w-400px">
                    <div className="symbol symbol-75px overflow-hidden me-3 shadow-sm">
                        <img src={record.img} alt />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::User details*/}
                    <div className="d-flex flex-column">
                        <a href className="text-dark mb-2 fw-bold text-two w-500px fs-5">{record.product}</a>
                        <div className="text-gray-800">
                          <span className="fs-6"><b>ASIN:</b> <span className="text-primary">{record.asin}</span></span> / 
                          <span className="fs-6 ms-1"><b>Brand:</b> <span className="text-primary">{record.brand}</span></span> /
                          <span className="fs-6 ms-1"><b>Category Name:</b> <span className="text-primary">{record.categoryName}</span></span>
                        </div>
                    </div>
                    </div>
          ),
        },
        {
          title: 'LQS Score',
          dataIndex: 'lqsScore',
          key: 'lqsScore',
          render: (_, record) => (
            <>
              <Tag color={record.lqsScore > 50 ? 'green' : 'orange'} className="fs-6 fw-bold py-1">{record.lqsScore}</Tag>
              {/* <Button><Icon icon="tabler:eye" width={25} height={25}></Icon></Button> */}
              <Button type=""  onClick={() => setModalOpen(true)} className="btn btn-icon h-35px w-35px" icon={<Icon icon="tabler:eye" width={25} height={25}></Icon>} />
            </>
          ),
        },
        {
          title: 'Retail Readiness',
          dataIndex: 'retailReadiness',
          key: 'retailReadiness',
          render: (_, record) => (
            <>
              <Tag color={record.retailReadiness > 50 ? 'green' : 'orange'} className="fs-6 fw-bold py-1">{record.retailReadiness}</Tag>
              {/* <Button><Icon icon="tabler:eye" width={25} height={25}></Icon></Button> */}
              <Button type="" className="btn btn-icon h-35px w-35px" icon={<Icon icon="tabler:eye" width={25} height={25}></Icon>} />
            </>
          ),
        },
        {
          title: 'BQS',
          dataIndex: 'bqs',
          key: 'bqs',
          render: (_, record) => (
            <>
              <Tag color={record.bqs > 50 ? 'green' : 'orange'} className="fs-6 fw-bold py-1">{record.bqs}</Tag>
              {/* <Button><Icon icon="tabler:eye" width={25} height={25}></Icon></Button> */}
              <Button type="" className="btn btn-icon h-35px w-35px" icon={<Icon icon="tabler:eye" width={25} height={25}></Icon>} />
            </>
          ),
        },
        {
          title: 'Listing Status',
          dataIndex: 'listingStatus',
          key: 'listingStatus',
          className:'text-center',
          render: (_, record) => (
            <>
             <div className="symbol symbol-35px overflow-hidden me-3 shadow-sm text-center">
                        <img src={record.listingStatus} alt />
                    </div>
            </>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: () => (
            <Space size="middle">
              <Button type="primary" size="large" className="fw-bold cus-btn">Analysis <Icon icon='tabler:report-search'></Icon></Button>
            </Space>
          ),
        },
      ];


  return (
    <>
    <Card className='' title="Product List">
        <Table rowSelection={rowSelection} columns={columns} dataSource={data}  pagination={false}/>
    </Card>
    </>
  )
}

export default ProductTable