import styled from "styled-components";

export const Wrapper = styled.div`

    .ant-card .ant-card-head {
    min-height: 70px;
}
input::placeholder, .ant-select .ant-select-selection-placeholder {
    color: #919191 !important;
}
.ant-segmented .ant-segmented-item-label {
    font-weight: 500;
    font-family: Inter;
    padding: 4px 11px;
}
.ant-segmented {
    background: rgb(243 243 243);
    height: 40px;
}
    button.ant-btn span {
    font-size: 15px;
    line-height: 0;
    display: flex;
}
    .ant-table-wrapper .ant-table-cell, .ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-tbody>tr>th, .ant-table-wrapper .ant-table-tbody>tr>td, .ant-table-wrapper tfoot>tr>th, .ant-table-wrapper tfoot>tr>td {
    position: relative;
    padding: 20px 16px;
    overflow-wrap: break-word;
}
   .custom-checkbox .ant-checkbox-inner {
  width: 20px; /* Adjust the width */
  height: 20px; /* Adjust the height */
}

.custom-checkbox .ant-checkbox {
  transform: scale(1.2); /* Adjust the scale to make it bigger or smaller */
} 
.ant-checkbox .ant-checkbox-inner {
    box-sizing: border-box;
    display: block;
    width: 20px;
    height: 20px;
    direction: ltr;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    border-collapse: separate;
    transition: all 0.3s;
}
.ant-table-wrapper .ant-table-summary tr td {
    border-top: 1px dashed #8dc5ff;
}
    .ant-table-summary tr td::after {
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    background-color: #c7e2ff;
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: "";
}
`;
