// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/report-center",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-minus-folder  fs-1"
          // style={{ color: "#9ACD32" }}
        ></i>
      </span>
    ),
    label: "Report Center",
    children: [
      {
        key: "/advertisement-report",
        icon: (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        ),
        label: GetLinks("/advertisement-report", "Advertisement Report"),
      },
    ],
  },
];
